import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
  internRepListData: null,
  internRepListCount: null,
  internRepListLoading: false,
  internRepListError: null,
  internsOfInternRepData: null,
  internsOfInternRepCount: null,
  internsOfInternRepLoading: false,
  internsOfInternRepError: null,
};


export const internRepListFunc = createAsyncThunk("internRep/internRepListFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query InternIFManager_internReps($pagination: Pagination) {
      internIFManager_internReps(pagination: $pagination) {
        count
        data {
          _id
          contact {
            email
          }
          createdAt
          displayImageUrl
          displayName
          internMedalOverview {
            bronzeCount
            silverCount
            goldCount
            vipCount
          }
          medal
          registeredBy {
            _id
            role
            displayName
            displayImageUrl
            contact {
              email
            }
          }
          role
          totalSalesAmount
          updatedAt
        }
      }
    }`,
    variables: {
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const internsOfInternRepFunc = createAsyncThunk("internRep/internsOfInternRepFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query InternIFManager_internsOfInternRep($internRepId: ID!) {
      internIFManager_internsOfInternRep(internRepId: $internRepId) {
        data {
          _id
          displayName
          displayImageUrl
          contact {
            email
          }
          medal
          totalSalesTo
        }
        count
      }
    }`,
    variables: {
      "internRepId": values.internRepId
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const internRep = createSlice({
  name: "internRep",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(internRepListFunc.pending, (state) => {
      state.internRepListLoading = true;
    }
    );
    builder.addCase(internRepListFunc.fulfilled, (state, action) => {
      state.internRepListLoading = false;
      console.log(action.payload, "action.payload");
      state.internRepListData = action.payload?.internIFManager_internReps?.data;
      state.internRepListCount = action.payload?.internIFManager_internReps?.count;
      state.internRepListError = action.payload.message;
    }
    );
    builder.addCase(internRepListFunc.rejected, (state, action) => {
      state.internRepListLoading = false;
      state.internRepListError = action.error.message;
    }
    );

    builder.addCase(internsOfInternRepFunc.pending, (state) => {
      state.internsOfInternRepLoading = true;
    }
    );
    builder.addCase(internsOfInternRepFunc.fulfilled, (state, action) => {
      state.internsOfInternRepLoading = false;
      state.internsOfInternRepData = action.payload?.internIFManager_internsOfInternRep?.data;
      state.internsOfInternRepCount = action.payload?.internIFManager_internsOfInternRep?.count;
      state.internsOfInternRepError = action.payload.message;
    }
    );
    builder.addCase(internsOfInternRepFunc.rejected, (state, action) => {
      state.internsOfInternRepLoading = false;
      state.internsOfInternRepError = action.error.message;
    }
    );
  }
});


export default internRep.reducer;