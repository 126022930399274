const resources = {
  en: {
    translation: {
      'Dashboard': 'Dashboard',
      'Clients': 'Clients',
      'Universities': 'Universities',
      'Finance': 'Finance',
      'Package Sales': 'Package Sales',
      'Revenue': 'Revenue',
      'Withdrawn': 'Withdrawn',
      'Withdrawable': 'Withdrawable',
      'Open': 'Open',
      'Waiting': 'Waiting',
      'Answered': 'Answered',
      'Closed': 'Closed',
      'Tickets': 'Tickets',
      'Client Bronze': 'Client Bronze',
      'Client Silver': 'Client Sliver',
      'Client Gold': 'Client Gold',
      'Client VIP': 'Client VIP',
      'University Bronze': 'University Bronze',
      'University Silver': 'University Silver',
      'University Gold': 'University Gold',
      'University VIP': 'University VIP',
      'Business Management': 'Business Management',
      'Client': 'Client',
      'University': 'University',
      'Ticket': 'Ticket',
      'Sales Representative': 'Sales Representative',
      "VIP": "VIP",
      "Client List": "Client List",
      "Add Client": "Add Client",
      "Name": "Name",
      "Company Name": "Company Name",
      "Standard / Pro / Special Sales": "Standard / Pro / Special Sales",
      "Total Sales ($)": "Total Sales ($)",
      "Status": "Status",
      "Medal": "Medal",
      "To add a client, please enter the client information here.": "To add a client, please enter the client information here.",
      "Bronze": "Bronze",
      "Silver": "Silver",
      "Gold": "Gold",
      "University List": "University List",
      "Add University": "Add University",
      "University Name": "University Name",
      "City": "City",
      "Country": "Country",
      "No data found": "No data found",
      "Standart Package Sales": "Standart Package Sales",
      "Pro Package Sales": "Pro Package Sales",
      "Special Package Sales": "Special Package Sales",
      "Finance List": "Finance List",
      "History": "History",
      "Withdraw Money": "Withdraw Money",
      "Type": "Type",
      "Name Surname": "Name Surname",
      "Sales": "Sales",
      "Enter the amount you want to withdraw.": "Enter the amount you want to withdraw.",
      "Amount": "Amount",
      "Cancel": "Cancel",
      "Withdraw": "Withdraw",
      "There is no ticket yet.": "There is no ticket yet.",
      "Profile": "Profile",
      "Settings": "Settings",
      "Sign Out": "Sign Out",
      "Notifications": "Notifications",
      "Mark all as read": "Mark all as read",
      "All": "All",
      "Unread": "Unread",
      "Archived": "Archived",
      "There are no notifications.": "There are no notifications.",
      "Save Changes": "Save Changes",
      "Change Profile Photo": "Change Profile Photo",
      "User Type": "User Type",
      "Individual": "Individual",
      "Organization": "Organization",
      "University Club": "University Club",
      "Organization Type": "Organization Type",
      "Public Company": "Public Company",
      "Self-employed": "Self-employed",
      "Government Agency": "Government Agency",
      "Nonprofit": "Nonprofit",
      "Sole Proprietorship": "Sole Proprietorship",
      "Limited Liability Company": "Limited Liability Company",
      "Corporation": "Corporation",
      "Partnership": "Partnership",
      "Organization Name": "Organization Name",
      "Organization Logo": "Organization Logo",
      "University Logo": "University Logo",
      "Logo": "Logo",
      "Surname": "Surname",
      "Email": "Email",
      "Phone": "Phone",
      "Birthday": "Birthday",
      "Gender": "Gender",
      "Nationality": "Nationality",
      "Language": "Language",
      "Language Level": "Language Level",
      "Address": "Address",
      "Notification": "Notification",
      "Security": "Security",
      "Policies": "Policies",
      "You can manage your notification preferences below.": "You can manage your notification preferences below.",
      "Push Notifications": "Push Notifications",
      "Email Notifications": "Email Notifications",
      "SMS Notifications": "SMS Notifications",
      "Save Settings": "Save Settings",
      "You can change your password in the field below.": "You can change your password in the field below.",
      "Old Password": "Old Password",
      "New Password": "New Password",
      "New Password Again": "New Password Again",
      "Old password": "Old password",
      "New password": "New password",
      "New password again": "New password again",
      "Change Password": "Change Password",
      "You can update the following settings to perform 2-factor authentication.": "You can update the following settings to perform 2-factor authentication.",
      "Two Factor with Email": "Two Factor with Email",
      "Two Factor with SMS": "Two Factor with SMS",
      "Welcome to InternIF!": "Welcome to InternIF!",
      "Personal Information": "Personal Information",
      "Could you give us some personal information about yourself?": "Could you give us some personal information about yourself?",
      "Account Type": "Account Type",
      "We would like you to provide some information about your account.": "We would like you to provide some information about your account.",
      "Detailed Information": "Detailed Information",
      "Could you give us some detailed information about yourself?": "Could you give us some detailed information about yourself?",
      "Last step": "Last step",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Female": "Female",
      "Male": "Male",
      "Other": "Other",
      "Finish": "Finish",
      "Continue": "Continue",
      "Back": "Back",
      "Companies": "Companies",
      "Company": "Company",
      "Company Admin List": "Company Admin List",
      "Add Company Admin": "Add Company Admin",
      "To add a company admin, please enter the company admin information here.": "To add a company admin, please enter the company admin information here.",
      "Add": "Add",
      "Company Type": "Company Type",
      "Employee": "Employee",
      "Country / City": "Country / City",
      "Intern": "Intern",
      "Intern List": "Intern List",
      "Interns": "Interns",
      "Intern Representative": "Intern Partner",
      "Company Representative": "Company Representative",
      "Add Intern": "Add Intern",
      "To add a intern, please enter the intern information here.": "To add a intern, please enter the intern information here.",

    },
  },
  tr: {
    translation: {
      'Dashboard': 'Panel',
      'Clients': 'Müşteriler',
      'Universities': 'Üniversiteler',
      'Finance': 'Finans',
      'Package Sales': 'Paket Satışları',
      'Revenue': 'Gelir',
      'Withdrawn': 'Çekilen Tutar',
      'Withdrawable': 'Çekilebilir Tutar',
      'Open': 'Açık',
      'Waiting': 'Bekleyen',
      'Answered': 'Cevaplanan',
      'Closed': 'Kapatılan',
      'Tickets': 'Destek Talepleri',
      'Client Bronze': 'Bronz Müşteri',
      'Client Silver': 'Gümüş Müşteri',
      'Client Gold': 'Altın Müşteri',
      'Client VIP': 'VIP Müşteri',
      'University Bronze': 'Bronz Üniversite',
      'University Silver': 'Gümüş Üniversite',
      'University Gold': 'Altın Üniversite',
      'University VIP': 'VIP Üniversite',
      'Business Management': 'İş Yönetimi',
      'Client': 'Müşteri',
      'University': 'Üniversite',
      'Ticket': 'Talep',
      'Sales Representative': 'Satış Temsilcisi',
      "VIP": "VIP",
      "Client List": "Müşteri Listesi",
      "Add Client": "Müşteri Ekle",
      "Name": "İsim",
      "Company Name": "Şirket Adı",
      "Standard / Pro / Special Sales": "Standart / Pro / Özel Satış",
      "Total Sales ($)": "Toplam Satış ($)",
      "Status": "Durum",
      "Medal": "Madalya",
      "To add a client, please enter the client information here.": "Müşteri eklemek için lütfen müşteri bilgilerini buraya girin.",
      "Bronze": "Bronz",
      "Silver": "Gümüş",
      "Gold": "Altın",
      "University List": "Üniversite Listesi",
      "Add University": "Üniversite Ekle",
      "University Name": "Üniversite Adı",
      "City": "Şehir",
      "Country": "Ülke",
      "No data found": "Veri bulunamadı",
      "Standart Package Sales": "Standart Paket Satışları",
      "Pro Package Sales": "Pro Paket Satışları",
      "Special Package Sales": "Özel Paket Satışları",
      "Finance List": "Finans Listesi",
      "History": "Geçmiş",
      "Withdraw Money": "Para Çek",
      "Type": "Tür",
      "Name Surname": "Ad Soyad",
      "Sales": "Satış",
      "Enter the amount you want to withdraw.": "Çekmek istediğiniz tutarı giriniz.",
      "Amount": "Tutar",
      "Cancel": "İptal",
      "Withdraw": "Çek",
      "There is no ticket yet.": "Henüz destek talebi yok.",
      "Profile": "Profil",
      "Settings": "Ayarlar",
      "Sign Out": "Çıkış Yap",
      "Notifications": "Bildirimler",
      "Mark all as read": "Tümünü okundu olarak işaretle",
      "All": "Tümü",
      "Unread": "Okunmamış",
      "Archived": "Arşivlenmiş",
      "There are no notifications.": "Henüz bildiriminiz yok.",
      "Save Changes": "Değişiklikleri Kaydet",
      "Change Profile Photo": "Profil Fotoğrafını Değiştir",
      "User Type": "Kullanıcı Türü",
      "Individual": "Bireysel",
      "Organization": "Kurumsal",
      "University Club": "Üniversite Kulübü",
      "Organization Type": "Kurum Türü",
      "Public Company": "Anonim Şirket",
      "Self-employed": "Serbest Meslek",
      "Government Agency": "Kamu Kurumu",
      "Nonprofit": "Kar Amacı Gütmeyen",
      "Sole Proprietorship": "Şahıs Şirketi",
      "Limited Liability Company": "Limited Şirket",
      "Corporation": "Kolektif Şirket",
      "Partnership": "Komandit Şirket",
      "Organization Name": "Kurum Adı",
      "Organization Logo": "Kurum Logosu",
      "University Logo": "Üniversite Logosu",
      "Logo": "Logo",
      "Surname": "Soyisim",
      "Email": "E-posta",
      "Phone": "Telefon",
      "Birthday": "Doğum Tarihi",
      "Gender": "Cinsiyet",
      "Nationality": "Uyruk",
      "Language": "Dil",
      "Language Level": "Dil Seviyesi",
      "Address": "Adres",
      "Notification": "Bildirim",
      "Security": "Güvenlik",
      "Policies": "Politikalar",
      "You can manage your notification preferences below.": "Bildirim tercihlerinizi aşağıdan yönetebilirsiniz.",
      "Push Notifications": "Push Bildirimleri",
      "Email Notifications": "E-posta Bildirimleri",
      "SMS Notifications": "SMS Bildirimleri",
      "Save Settings": "Ayarları Kaydet",
      "You can change your password in the field below.": "Şifrenizi aşağıdaki alandan değiştirebilirsiniz.",
      "Old Password": "Eski Şifre",
      "New Password": "Yeni Şifre",
      "New Password Again": "Yeni Şifre Tekrar",
      "Old password": "Eski şifre",
      "New password": "Yeni şifre",
      "New password again": "Yeni şifre tekrar",
      "Change Password": "Şifre Değiştir",
      "You can update the following settings to perform 2-factor authentication.": "2 faktörlü kimlik doğrulama yapmak için aşağıdaki ayarları güncelleyebilirsiniz.",
      "Two Factor with Email": "E-posta ile 2 Faktörlü Doğrulama",
      "Two Factor with SMS": "SMS ile 2 Faktörlü Doğrulama",
      "Welcome to InternIF!": "InternIF'e Hoş Geldiniz!",
      "Personal Information": "Kişisel Bilgiler",
      "Could you give us some personal information about yourself?": "Kişisel bilgileriniz hakkında bize birkaç ipucu verebilir misiniz?",
      "Account Type": "Hesap Türü",
      "We would like you to provide some information about your account.": "Hesabınız hakkında bazı bilgiler vermenizi istiyoruz.",
      "Detailed Information": "Detaylı Bilgi",
      "Could you give us some detailed information about yourself?": "Kendiniz hakkında bize biraz daha detaylı bilgi verebilir misiniz?",
      "Last step": "Son adım",
      "First Name": "Ad",
      "Last Name": "Soyad",
      "Female": "Kadın",
      "Male": "Erkek",
      "Other": "Diğer",
      "Finish": "Bitir",
      "Continue": "Devam Et",
      "Back": "Geri",
      "Companies": "Şirketler",
      "Company": "Şirket",
      "Company Admin List": "Şirket Yönetici Listesi",
      "Add Company Admin": "Şirket Yöneticisi Ekle",
      "To add a company admin, please enter the company admin information here.": "Şirket yöneticisi eklemek için lütfen şirket yöneticisi bilgilerini buraya girin.",
      "Add": "Ekle",
      "Company Type": "Şirket Türü",
      "Employee": "Çalışan",
      "Country / City": "Ülke / Şehir",
      "Intern": "Stajyer",
      "Intern List": "Stajyer Listesi",
      "Interns": "Stajyerler",
      "Intern Representative": "Stajyer Temsilcisi",
      "Company Representative": "Şirket Temsilcisi",
      "Add Intern": "Stajyer Ekle",
      "To add a intern, please enter the intern information here.": "Stajyer eklemek için lütfen stajyer bilgilerini buraya girin.",
    },
  },
};

export default resources;
