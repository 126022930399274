import { useTranslation } from 'react-i18next';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination } from '@mui/material'
import Label from 'src/components/label/label';


const CompanyListShortTable = ({ data, page, pageSize, dataCount, handleChangePage }) => {

    const { t } = useTranslation();

    return <TableContainer component={Paper} >
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>{t("Full Name")}</TableCell>
                    <TableCell align="center">{t("Company Name")}</TableCell>
                    <TableCell align="center">{t("Country / City")}</TableCell>
                    <TableCell align="center">{t("Total Sales ($)")}</TableCell>
                    <TableCell align="center">{t("Medal")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data ?
                    data.map((item, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{page === 1 ? index + 1 : (page - 1) * pageSize + index + 1}</TableCell>
                            <TableCell component="th" scope="row">{item.companyAdmin?.displayName}</TableCell>
                            <TableCell align="center">{item.displayName}</TableCell>
                            <TableCell align="center">{item.country?.name} / {item.city?.name}</TableCell>
                            <TableCell align="center">$ {item.totalSalesTo}</TableCell>
                            <TableCell align="center">
                                {<Label variant="filled" color={item.medal}>
                                    {item.medal === 'bronze' ? 'Bronze' :
                                        item.medal === 'silver' ? 'Silver' :
                                            item.medal === 'gold' ? 'Gold' :
                                                item.medal === 'vip' ? 'VIP' : 'None'}
                                </Label>}
                            </TableCell>
                        </TableRow>
                    )) :
                    <TableRow>
                        <TableCell colSpan={7} align="center">No data found</TableCell>
                    </TableRow>
                }
            </TableBody>

        </Table>
        <Box display="flex" justifyContent="center" p={2}>
            <Pagination
                count={Math.ceil(dataCount / pageSize)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                style={{ marginLeft: 10 }}
            />
        </Box>
    </TableContainer>
}

export default CompanyListShortTable
