import { Box, Typography, List, ListItem, Grid, ListItemText, Button } from '@mui/material'
import PaymentsIcon from '@mui/icons-material/Payments';
import OutboxIcon from '@mui/icons-material/Outbox';
import { useTranslation } from 'react-i18next';

const PackageBox = ({ medalType, bgColor, count, count2, letterSpace, uppercase, features }) => {

    const { t } = useTranslation();
    const handleClickOpen = () => {
        console.log("open")
    };


    return <Box bgcolor={bgColor} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2} position="relative"
        boxShadow={medalType === "VIP" ? "0px 0px 5px rgba(0, 0, 0, 0.50)" : 0}>
        <Box height={20} />
        <Typography variant="h4" style={{ letterSpacing: letterSpace ? letterSpace : "4.5px" }} color="#000" >
            {uppercase ? medalType.toUpperCase() : medalType} </Typography>
        {(medalType === "Standard" || "Pro" || "Special") && <Box height={20} />}
        <Typography variant="h4" color="#000">{count}</Typography>
        <Typography variant="h5" color="#000" >{count2}</Typography>
        {(medalType === "Standart Package" || "Pro Package" || "Special Package") && <Box>
            <List dense={true}>
                {features.map((feature, index) => (
                    <ListItem key={index}>
                        {/* <ListItemIcon>
                            <KeyboardArrowRightIcon />
                        </ListItemIcon> */}
                        <ListItemText
                            primary={` ◦ ${feature.title}`}
                            secondary={true ? feature.description : null}
                        />
                    </ListItem>
                ))}
            </List>

            <Grid>
                <Button onClick={handleClickOpen} style={{ marginRight: 20 }}
                    variant="outlined" color="primary" size="large"> <OutboxIcon color="white"
                        fontSize="medium" style={{ marginRight: 10 }} />
                    {t("Send Package")}
                </Button>
                <Button onClick={() => null}
                    variant="contained" color="primary" size="large"> <PaymentsIcon color="white"
                        fontSize="medium" style={{ marginRight: 10 }} />
                    {t("Buy Package")}
                </Button>

            </Grid>
        </Box>}
    </Box>
}

export default PackageBox