import { useSelector } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import BizLayout from 'layouts/biz/BizLayout'
import { PackageSales, Revenue, Withdrawable, Withdrawn, Open, Waiting, Answered, Closed } from 'assets'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';
import { MedalBox } from "components";

const Dashboard = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { clientsOverviewData } = useSelector((state) => state.client);
    const {  universitiesOverviewData } = useSelector((state) => state.university);

    return <BizLayout>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t('Dashboard')}</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6} mt={2}>
                    <Box
                        onClick={() => navigate('/business-partner')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }}
                        display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Business Partner')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Bronze')}
                                    bgColor="#FFEFE2"
                                    count={clientsOverviewData?.bronzeCount}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Silver')}
                                    bgColor="#F5F5F5"
                                    count={clientsOverviewData?.silverCount}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Gold')}
                                    bgColor="#fff1ba"
                                    count={clientsOverviewData?.goldCount}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('VIP')}
                                    bgColor="#383e42"
                                    count={clientsOverviewData?.vipCount}
                                    uppercase
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6} mt={2}>
                    <Box sx={{ boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white' }} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Universities')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t("Bronze")}
                                    bgColor="#FFEFE2"
                                    count={universitiesOverviewData?.bronzeCount}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t("Silver")}
                                    bgColor="#F5F5F5"
                                    count={universitiesOverviewData?.silverCount}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t("Gold")}
                                    bgColor="#fff1ba"
                                    count={universitiesOverviewData?.goldCount}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t("VIP")}
                                    bgColor="#383e42"
                                    count={universitiesOverviewData?.vipCount}
                                    uppercase
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12} mt={2}>
                    <Box sx={{ boxShadow: 20, p: 2, borderRadius: 2, bgcolor: 'white' }} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Finance')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <img src={PackageSales} width={30} alt="package-sales" style={{ marginTop: 1 }} />
                                    <Typography variant="h6">{t('Package Sales')}</Typography>
                                    <Typography variant="h2">10</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <img src={Revenue} width={30} alt="revenue" style={{ marginTop: 1 }} />
                                    <Typography variant="h6">{t('Revenue')}</Typography>
                                    <Typography variant="h2">4</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <img src={Withdrawn} width={30} alt="withdrawn" style={{ marginTop: 1 }} />
                                    <Typography variant="h6">{t('Withdrawn')}</Typography>
                                    <Typography variant="h2">1</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <img src={Withdrawable} width={30} alt="withdrawable" style={{ marginTop: 1 }} />
                                    <Typography variant="h6">{t('Withdrawable')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12} mt={2}>
                    <Box sx={{ boxShadow: 20, p: 2, borderRadius: 2, bgcolor: 'white' }} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Tickets')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <img src={Open} width={30} alt="package-sales" style={{ marginTop: 1 }} />
                                    <Typography variant="h6">{t('Open')}</Typography>
                                    <Typography variant="h2">10</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <img src={Waiting} width={30} alt="revenue" style={{ marginTop: 1 }} />
                                    <Typography variant="h6">{t('Waiting')}</Typography>
                                    <Typography variant="h2">4</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <img src={Answered} width={30} alt="withdrawn" style={{ marginTop: 1 }} />
                                    <Typography variant="h6">{t('Answered')}</Typography>
                                    <Typography variant="h2">1</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} pb={2} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <img src={Closed} width={30} alt="withdrawable" style={{ marginTop: 1 }} />
                                    <Typography variant="h6">{t('Closed')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    </BizLayout>
}

export default Dashboard