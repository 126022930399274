
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box, Typography, Stack, Tabs, Tab, Card, FormGroup, FormControlLabel, Switch, Button
    , TextField, FormControl, InputLabel, Select, MenuItem, Divider, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { BasicInputPassword } from 'components';
import BizLayout from 'layouts/biz/BizLayout';
import PropTypes from 'prop-types';
import { NotificationsNone, Security, Description, InfoOutlined, Save } from '@mui/icons-material';
import { Formik } from 'formik';
import * as yup from 'yup';
import regexPattern from 'src/utils/regexPattern';
import { resetPasswordUsingOldFunc, get2faFunc, update2faFunc } from 'context/auth/auth';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
    userBillingInfoFunc, self_updateUserInvoiceInfoFunc, self_updateOrganizationInvoiceInfoFunc,
    self_updateUserBankAccountFunc, organizationBillingInfoFunc
} from 'context/common/packages';
import { useFormik } from 'formik';

const info = [
    "Notification eksik./backend", "2fa eksik", "Policies eksik/business"
]

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pl: 0, pt: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Settings = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken, userRole } = useSelector(state => state.auth);
    const { userBillingInfoData, userBillingInfoLoading, userBillingInfoError,
        organizationBillingInfoData, organizationBillingInfoLoading, organizationBillingInfoError } = useSelector(state => state.packages);
    const { userType, getUserData } = useSelector(state => state.user);

    const { t } = useTranslation()
    const [value, setValue] = useState(0);
    const [pushNotification, setPushNotification] = useState(true);
    const [emailNotification, setEmailNotification] = useState(true);
    const [smsNotification, setSmsNotification] = useState(false);
    const [twoFactorEmail, setTwoFactorEmail] = useState(false);
    const [twoFactorSms, setTwoFactorSms] = useState(false);
    const [openTwoFactor, setOpenTwoFactor] = useState(false);
    const [openBankAccountAccept, setOpenBankAccountAccept] = useState(false);
    const [invoiceType, setInvoiceType] = useState(userBillingInfoData?.invoiceInfo?.type || "");

    const handlePushNotificationChange = () => {
        setPushNotification(!pushNotification);
    };

    const handleEmailNotificationChange = () => {
        setEmailNotification(!emailNotification);
    };

    const handleSmsNotificationChange = () => {
        setSmsNotification(!smsNotification);
    };

    const handleTwoFactorEmailChange = () => {
        setTwoFactorEmail(!twoFactorEmail);
    };

    const handleTwoFactorSmsChange = () => {
        setTwoFactorSms(!twoFactorSms);
    };

    const handleSaveToFactor = () => {
        setOpenTwoFactor(true)
    };

    const handleUpdate2fa = () => {
        dispatch(update2faFunc({ accessToken: accessToken, email: twoFactorEmail })).then((res) => {
            if (res.payload?.isSuccess) {
                enqueueSnackbar('2fa updated successfully!', { variant: 'success' });
                setOpenTwoFactor(false)
            } else {
                enqueueSnackbar(res.payload?.response?.data?.message, { variant: 'error' });
                setOpenTwoFactor(false)
            }
        })
    }

    const handleSaveSettings = () => {
        console.log('Push Notification:', pushNotification);
        console.log('Email Notification:', emailNotification);
        console.log('SMS Notification:', smsNotification);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const validationSchema = yup.object({
        oldPassword: yup
            .string()
            .required('Old Password is required'),

        password: yup
            .string()
            .required('Password is required')
            .matches(regexPattern.atLeastOnelowerCases, "Must contain lower case!")
            .matches(regexPattern.atLeastOneupperCases, "Must contain upper case!")
            .matches(regexPattern.atLeastOnenumbers, "Must contain number!")
            .matches(regexPattern.atLeastOnepecialCharacters, "Must contain special character!")
            .min(8, "Password must be at least 8 characters!")
            .required('Password is required!'),

        passwordAgain: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Password confirmation is required'),
    });

    const handleResetPassword = (values) => {
        dispatch(resetPasswordUsingOldFunc({ accessToken, oldPassword: values?.oldPassword, newPassword: values?.password })).then((res) => {
            if (res.payload.isSuccess) {
                enqueueSnackbar('Password changed successfully!', { variant: 'success' });
            } else {
                enqueueSnackbar(res.payload?.response?.data?.message, { variant: 'error' });
            }
        })
    }

    console.log(userRole)
    console.log(userType)

    const formikBank = useFormik({
        enableReinitialize: true,
        validationSchema: yup.object({
            id: userType === "individual" ? yup.string().required('Id is required').min(3, 'Id must be at least 3 characters') : null,
            accountName: userType === "company" ? yup.string().required('Account Name is required') : null,
            bankName: yup.string().required('Bank Name is required'),
            branchCode: yup.string().required('Branch Code is required'),
            iban: yup.string().matches(/^[A-Z]{2}\d{2}[A-Za-z0-9]{1,30}$/, 'Invalid IBAN').required('IBAN is required'),
            routingNumber: yup.string().required('Routing Number is required'),
            swiftOrBICCode: yup.string().required('Swift or BIC Code is required'),
        }),
        initialValues: {
            id: userType === "individual" ? userBillingInfoData?.bankAccountInfo?.personal?.id : '',
            accountName: userType === "company" ? userBillingInfoData?.bankAccountInfo?.business?.bankAccountName : '',
            bankName: userType === "company" ? userBillingInfoData?.bankAccountInfo?.business?.bankName : userBillingInfoData?.bankAccountInfo?.personal?.bankName,
            branchCode: userType === "company" ? userBillingInfoData?.bankAccountInfo?.business?.bankBranchCode : userBillingInfoData?.bankAccountInfo?.personal?.bankBranchCode,
            iban: userType === "company" ? userBillingInfoData?.bankAccountInfo?.business?.iban : userBillingInfoData?.bankAccountInfo?.personal?.iban,
            routingNumber: userType === "company" ? userBillingInfoData?.bankAccountInfo?.business?.routingNumber : userBillingInfoData?.bankAccountInfo?.personal?.routingNumber,
            swiftOrBICCode: userType === "company" ? userBillingInfoData?.bankAccountInfo?.business?.SWIFTorBICCode : userBillingInfoData?.bankAccountInfo?.personal?.SWIFTorBICCode,
            name: userType === "individual" ? getUserData?.personalInformation?.name : '',
            surname: userType === "individual" ?getUserData?.personalInformation?.surname : '',
            email: userType === "individual" ?getUserData?.contact?.email : '',
        },
        onSubmit: () => {
            setOpenBankAccountAccept(true)
        },
    });

    const handleSubmitBank = () => {

        if (userRole === "b2bSalesRep" || userRole === "b2bCompanyRep" || (userRole === "b2bInternRep" && userType === "company") ||  (userRole === "internIFManager" && userType === "company")
            || (userRole === "internIFManager" && userType === "organization")) {
            dispatch(self_updateUserBankAccountFunc({ accessToken: accessToken, values: formikBank.values, type: "business" })).then((res) => {
                if (res.payload.data?.self_updateUserBankAccountInfo?.success) {
                    enqueueSnackbar('Bank Account updated successfully!', { variant: 'success' });
                    dispatch(userBillingInfoFunc({ accessToken: accessToken }))
                } else {
                    enqueueSnackbar("Bank Account couldn't updated!", { variant: 'error' });
                }
            })
        }
        if ((userRole === "b2bInternRep" && userType === "individual")
            || (userRole === "internIFManager" && userType === "individual")) {
            dispatch(self_updateUserBankAccountFunc({ accessToken: accessToken, values: formikBank.values, type: "personal" })).then((res) => {
                if (res.payload.data?.self_updateUserBankAccountInfo?.success) {
                    enqueueSnackbar('Bank Account updated successfully!', { variant: 'success' });
                    dispatch(userBillingInfoFunc({ accessToken: accessToken }))
                } else {
                    enqueueSnackbar("Bank Account couldn't updated!", { variant: 'error' });
                }
            })
        }
    }

    const formikInvoice = useFormik({
        initialValues: {
            id: userBillingInfoData?.invoiceInfo?.personal?.id,
            taxOffice: userBillingInfoData?.invoiceInfo?.business?.taxOffice,
            taxNumber: userBillingInfoData?.invoiceInfo?.business?.taxNumber,
            phone: invoiceType === "business" ? userBillingInfoData?.invoiceInfo?.business?.phoneNumber :
                invoiceType === "personal" ? userBillingInfoData?.invoiceInfo?.personal?.phoneNumber : '',
            country: invoiceType === "business" ? userBillingInfoData?.invoiceInfo?.business?.country :
                invoiceType === "personal" ? userBillingInfoData?.invoiceInfo?.personal?.country : '',
            city: invoiceType === "business" ? userBillingInfoData?.invoiceInfo?.business?.city :
                invoiceType === "personal" ? userBillingInfoData?.invoiceInfo?.personal?.city : '',
            zip: invoiceType === "business" ? userBillingInfoData?.invoiceInfo?.business?.zip :
                invoiceType === "personal" ? userBillingInfoData?.invoiceInfo?.personal?.zip : '',
            address: invoiceType === "business" ? userBillingInfoData?.invoiceInfo?.business?.address :
                invoiceType === "personal" ? userBillingInfoData?.invoiceInfo?.personal?.address : '',
            corporateName: userBillingInfoData?.invoiceInfo?.business?.corporateName,
            name: getUserData?.personalInformation?.name,
            surname: getUserData?.personalInformation?.surname,
            email: getUserData?.contact?.email,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            id: invoiceType === "personal" && yup.string().required('Id is required').min(3, 'Id must be at least 3 characters'),
            corporateName: invoiceType === "business" && yup.string().required('Corporate Name is required'),
            taxOffice: invoiceType === "business" && yup.string().required('Tax Office is required'),
            taxNumber: invoiceType === "business" && yup.string().required('Tax Number is required'),
            phone: yup.string().required('Phone is required'),
            country: yup.string().required('Country is required'),
            city: yup.string().required('City is required'),
            zip: yup.string().required('Zip is required'),
            address: yup.string().required('Address is required'),

        }),
        onSubmit: (values) => {
            handleSubmitInvoice(values)
        },
    });

    const handleSubmitInvoice = (values) => {
        if (invoiceType === "personal") {
            if (userRole === "b2bClient") {
                dispatch(self_updateUserInvoiceInfoFunc({ accessToken: accessToken, values: values, type: "personal" })).then((res) => {
                    if (res.payload.data?.self_updateUserInvoiceInfo?.success) {
                        enqueueSnackbar('Invoice Info updated successfully!', { variant: 'success' });
                        dispatch(userBillingInfoFunc({ accessToken: accessToken }))
                    } else {
                        enqueueSnackbar("Invoice Info couldn't updated!", { variant: 'error' });
                    }
                })
            }
        }
        if (invoiceType === "business") {
            if (userRole === "b2bClient") {
                dispatch(self_updateUserInvoiceInfoFunc({ accessToken: accessToken, values: values, type: "business" })).then((res) => {
                    if (res.payload.data?.self_updateUserInvoiceInfo?.success) {
                        enqueueSnackbar('Invoice Info updated successfully!', { variant: 'success' });
                        dispatch(userBillingInfoFunc({ accessToken: accessToken }))
                    } else {
                        enqueueSnackbar("Invoice Info couldn't updated!", { variant: 'error' });
                    }
                })
            }
        }
    }




    useEffect(() => {
        if (accessToken && (
            userRole === "b2bSalesRep" || userRole === "b2bCompanyRep" || userRole === "b2bInternRep" || userRole === "b2bClient"
        )) {
            dispatch(userBillingInfoFunc({ accessToken: accessToken }))
        }
        accessToken && dispatch(get2faFunc({ accessToken: accessToken })).then((res) => {
            if (res.payload?.isSuccess) {
                setTwoFactorEmail(res.payload.data.email)
                setTwoFactorSms(res.payload.data.sms)
            }
        })
    }, [accessToken, dispatch, userRole])





    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }}>
            <Stack flexDirection={{ xs: "column", lg: "row" }} justifyContent="space-between">
                <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }}>{t("Settings")}</Typography>
            </Stack>

            <Box height={30} />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="tabs">
                    <Tab icon={<NotificationsNone fontSize='small' />} label={t("Notification")} {...a11yProps(0)} />
                    <Tab icon={<Security fontSize='small' />} label={t("Security")} {...a11yProps(1)} />
                    {/* Invoice for Personal */}
                    {userRole === "b2bSalesRep" && <Tab icon={<InfoOutlined fontSize='small' />} label={t("Invoice Info")} {...a11yProps(2)} />}
                    {/*  Invoice for Business */}
                    {userRole === "b2bClient" && <Tab icon={<InfoOutlined fontSize='small' />} label={t("Invoice Info")} {...a11yProps(3)} />}
                    {/* Bank Account for Personal */}
                    {((userRole === "b2bInternRep" && userType === "individual") || (userRole === "internIFManager" && userType === "individual"))
                        && <Tab icon={<InfoOutlined fontSize='small' />} label={t("Bank Account")} {...a11yProps(4)} />}
                    {/* Bank Account for Business */}
                    {(userRole === "b2bSalesRep" || userRole === "b2bCompanyRep" || (userRole === "b2bInternRep" && userType === "company")
                        || (userRole === "internIFManager" && userType === "company"))
                        && <Tab icon={<InfoOutlined fontSize='small' />} label={t("Bank Account")} {...a11yProps(5)} />}
                    <Tab icon={<Description fontSize='small' />} label={t("Policies")} {...a11yProps(6)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <Card elevation={3} style={{ padding: '20px', margin: 0 }}>

                    <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can manage your notification preferences below.")}</Typography><br /><br />
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={pushNotification} onChange={handlePushNotificationChange} />}
                            label={t("Push Notifications")}
                        />
                        <FormControlLabel
                            control={<Switch checked={emailNotification} onChange={handleEmailNotificationChange} />}
                            label={t("Email Notifications")}
                        />
                        {/* <FormControlLabel
                            control={<Switch checked={smsNotification} onChange={handleSmsNotificationChange} />}
                            label={t("SMS Notifications")}
                        /> */}
                    </FormGroup>
                    <Box height={30} />
                    <Button variant="contained" color="primary" size='large' onClick={handleSaveSettings}>
                        {t("Save Settings")}
                    </Button>
                </Card>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <Card style={{ padding: '20px', margin: 0 }}>

                    <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can change your password in the field below.")}</Typography><br /><br />

                    <Formik initialValues={{ password: '', oldPassword: '' }} validationSchema={validationSchema}
                        onSubmit={handleResetPassword}>
                        {({ handleSubmit, handleChange, handleBlur, errors, values, touched }) => (
                            <form onSubmit={handleSubmit}>
                                <Stack spacing={1} width={{ xs: '100%', md: '50%', lg: '50%' }}>
                                    <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} mb={0}>
                                        <BasicInputPassword
                                            name="oldPassword"
                                            type="password"
                                            label={t("Old Password")}
                                            placeholder={t("Old password")}
                                            value={values?.oldPassword}
                                            error={touched.oldPassword && errors.oldPassword}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                        />
                                    </Stack>
                                    <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
                                        <BasicInputPassword
                                            name="password"
                                            type="password"
                                            label={t("New Password")}
                                            placeholder={t("New password")}
                                            value={values?.password}
                                            error={touched.password && errors.password}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                        />
                                        <BasicInputPassword
                                            name="passwordAgain"
                                            type="password"
                                            label={t("New Password Again")}
                                            placeholder={t("New password again")}
                                            value={values?.passwordAgain}
                                            error={touched.passwordAgain && errors.passwordAgain}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                        />
                                    </Stack>
                                    <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} mb={2}>
                                        <Button variant="contained" color="primary" type='submit' size='large' >
                                            {t("Change Password")}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </form>
                        )}
                    </Formik>

                </Card>

                <Card style={{ padding: '20px', margin: 0, marginTop: '20px' }}>
                    <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can update the following settings to perform 2-factor authentication.")}</Typography><br />
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={twoFactorEmail} onChange={handleTwoFactorEmailChange} />}
                            label={t("Two Factor with Email")}
                        />
                        {/* <FormControlLabel
                            control={<Switch checked={twoFactorSms} onChange={handleTwoFactorSmsChange} />}
                            label={t("Two Factor with SMS")}
                        /> */}
                    </FormGroup>
                    <Box height={10} />
                    <Button variant="contained" color="primary" onClick={handleSaveToFactor} size='large'>
                        {t("Save Settings")}
                    </Button>

                </Card>

            </CustomTabPanel>


            {/* invoice info business  */}
            <CustomTabPanel value={value} index={
                (userRole === "b2bClient" ? 2 : -1)
            }>
                <Card style={{ padding: '20px', margin: 0 }}>
                    <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can update your invoice information below.")}</Typography><br /><br />
                    <form onSubmit={formikInvoice.handleSubmit}>
                        <Stack gap={2} py={2} flexDirection={{ xs: "column" }} width={{ xs: '100%', md: '50%', xl: '33%' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t("Invoice Type")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='invoiceType'
                                    value={invoiceType}
                                    label={t("Invoice Type")}
                                    onChange={(e) => {
                                        formikInvoice.setValues({
                                            ...formikInvoice.values,
                                            id: '',
                                            phone: '',
                                            country: '',
                                            city: '',
                                            zip: '',
                                            address: '',
                                        })
                                        setInvoiceType(e.target.value)
                                    }}
                                >
                                    <MenuItem value={"personal"}>Personal</MenuItem>
                                    <MenuItem value={"business"}>Business</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        {invoiceType === "personal" &&
                            <Stack gap={2} flexDirection={{ xs: "column" }} width={{ xs: '100%', md: '50%', xl: '33%' }}>
                                <TextField
                                    fullWidth
                                    name="id"
                                    label={t("ID")}
                                    variant="outlined"
                                    value={formikInvoice.values.id}
                                    onChange={formikInvoice.handleChange}
                                    onBlur={formikInvoice.handleBlur}
                                    error={formikInvoice.touched.id && Boolean(formikInvoice.errors.id)}
                                    helperText={formikInvoice.touched.id && formikInvoice.errors.id}
                                    inputProps={{ maxLength: 35 }}
                                />
                                <Stack flexDirection={"row"} gap={1}>
                                    <TextField
                                        fullWidth
                                        name="phone"
                                        label={t("Phone")}
                                        variant="outlined"
                                        onBlur={formikInvoice.handleBlur}
                                        placeholder='Corporate Phone Number'
                                        value={formikInvoice.values.phone}
                                        onChange={formikInvoice.handleChange}
                                        error={formikInvoice.touched.phone && Boolean(formikInvoice.errors.phone)}
                                        helperText={formikInvoice.touched.phone && formikInvoice.errors.phone}
                                        inputProps={{ maxLength: 35 }}
                                    />
                                    <TextField
                                        fullWidth
                                        name="zip"
                                        label={t("ZIP")}
                                        variant="outlined"
                                        onBlur={formikInvoice.handleBlur}
                                        placeholder='Corporate ZIP Code'
                                        value={formikInvoice.values.zip}
                                        onChange={formikInvoice.handleChange}
                                        error={formikInvoice.touched.zip && Boolean(formikInvoice.errors.zip)}
                                        helperText={formikInvoice.touched.zip && formikInvoice.errors.zip}
                                        inputProps={{ maxLength: 35 }}
                                    />
                                </Stack>
                                <Stack flexDirection={"row"} gap={1}>
                                    <TextField
                                        name="country"
                                        fullWidth
                                        label={t("Country")}
                                        variant="outlined"
                                        onBlur={formikInvoice.handleBlur}
                                        placeholder='Corporate Country'
                                        value={formikInvoice.values.country}
                                        onChange={formikInvoice.handleChange}
                                        error={formikInvoice.touched.country && Boolean(formikInvoice.errors.country)}
                                        helperText={formikInvoice.touched.country && formikInvoice.errors.country}
                                        inputProps={{ maxLength: 35 }}
                                    />
                                    <TextField
                                        fullWidth
                                        name="city"
                                        label={t("City")}
                                        variant="outlined"
                                        onBlur={formikInvoice.handleBlur}
                                        placeholder='Corporate City'
                                        value={formikInvoice.values.city}
                                        onChange={formikInvoice.handleChange}
                                        error={formikInvoice.touched.city && Boolean(formikInvoice.errors.city)}
                                        helperText={formikInvoice.touched.city && formikInvoice.errors.city}
                                        inputProps={{ maxLength: 35 }}
                                    />
                                </Stack>
                                <TextField
                                    fullWidth
                                    name="address"
                                    label={t("Address")}
                                    variant="outlined"
                                    onBlur={formikInvoice.handleBlur}
                                    placeholder='Corporate Address'
                                    value={formikInvoice.values.address}
                                    onChange={formikInvoice.handleChange}
                                    error={formikInvoice.touched.address && Boolean(formikInvoice.errors.address)}
                                    helperText={formikInvoice.touched.address && formikInvoice.errors.address}
                                    inputProps={{ maxLength: 35 }}
                                />
                            </Stack>}
                        {invoiceType === "business" &&
                            <Stack gap={2} flexDirection={{ xs: "column" }} width={{ xs: '100%', md: '50%', xl: '33%' }}>
                                <TextField
                                    fullWidth
                                    name="corporateName"
                                    label={t("Corporate Name")}
                                    variant="outlined"
                                    value={formikInvoice.values.corporateName}
                                    onChange={formikInvoice.handleChange}
                                    onBlur={formikInvoice.handleBlur}
                                    error={formikInvoice.touched.corporateName && Boolean(formikInvoice.errors.corporateName)}
                                    helperText={formikInvoice.touched.corporateName && formikInvoice.errors.corporateName}
                                    inputProps={{ maxLength: 35 }}
                                />
                                <TextField
                                    fullWidth
                                    name="taxOffice"
                                    label={t("Tax Office")}
                                    placeholder='Corporate Tax Office'
                                    variant="outlined"
                                    value={formikInvoice.values.taxOffice}
                                    onChange={formikInvoice.handleChange}
                                    onBlur={formikInvoice.handleBlur}
                                    error={formikInvoice.touched.taxOffice && Boolean(formikInvoice.errors.taxOffice)}
                                    helperText={formikInvoice.touched.taxOffice && formikInvoice.errors.taxOffice}
                                    inputProps={{ maxLength: 35 }}
                                />
                                <TextField
                                    fullWidth
                                    name="taxNumber"
                                    label={t("Tax Number")}
                                    placeholder='Corporate Tax Number'
                                    onBlur={formikInvoice.handleBlur}
                                    variant="outlined"
                                    value={formikInvoice.values.taxNumber}
                                    onChange={formikInvoice.handleChange}
                                    error={formikInvoice.touched.taxNumber && Boolean(formikInvoice.errors.taxNumber)}
                                    helperText={formikInvoice.touched.taxNumber && formikInvoice.errors.taxNumber}
                                    inputProps={{ maxLength: 35 }}
                                />
                                <Stack flexDirection={"row"} gap={1}>
                                    <TextField
                                        fullWidth
                                        name="phone"
                                        label={t("Phone")}
                                        variant="outlined"
                                        onBlur={formikInvoice.handleBlur}
                                        placeholder='Corporate Phone Number'
                                        value={formikInvoice.values.phone}
                                        onChange={formikInvoice.handleChange}
                                        error={formikInvoice.touched.phone && Boolean(formikInvoice.errors.phone)}
                                        helperText={formikInvoice.touched.phone && formikInvoice.errors.phone}
                                        inputProps={{ maxLength: 35 }}
                                    />
                                    <TextField
                                        fullWidth
                                        name="zip"
                                        label={t("ZIP")}
                                        variant="outlined"
                                        onBlur={formikInvoice.handleBlur}
                                        placeholder='Corporate ZIP Code'
                                        value={formikInvoice.values.zip}
                                        onChange={formikInvoice.handleChange}
                                        error={formikInvoice.touched.zip && Boolean(formikInvoice.errors.zip)}
                                        helperText={formikInvoice.touched.zip && formikInvoice.errors.zip}
                                        inputProps={{ maxLength: 35 }}
                                    />
                                </Stack>
                                <Stack flexDirection={"row"} gap={1}>
                                    <TextField
                                        name="country"
                                        fullWidth
                                        label={t("Country")}
                                        variant="outlined"
                                        onBlur={formikInvoice.handleBlur}
                                        placeholder='Corporate Country'
                                        value={formikInvoice.values.country}
                                        onChange={formikInvoice.handleChange}
                                        error={formikInvoice.touched.country && Boolean(formikInvoice.errors.country)}
                                        helperText={formikInvoice.touched.country && formikInvoice.errors.country}
                                        inputProps={{ maxLength: 35 }}
                                    />
                                    <TextField
                                        fullWidth
                                        name="city"
                                        label={t("City")}
                                        variant="outlined"
                                        onBlur={formikInvoice.handleBlur}
                                        placeholder='Corporate City'
                                        value={formikInvoice.values.city}
                                        onChange={formikInvoice.handleChange}
                                        error={formikInvoice.touched.city && Boolean(formikInvoice.errors.city)}
                                        helperText={formikInvoice.touched.city && formikInvoice.errors.city}
                                        inputProps={{ maxLength: 35 }}
                                    />
                                </Stack>
                                <TextField
                                    fullWidth
                                    name="address"
                                    label={t("Address")}
                                    variant="outlined"
                                    onBlur={formikInvoice.handleBlur}
                                    placeholder='Corporate Address'
                                    value={formikInvoice.values.address}
                                    onChange={formikInvoice.handleChange}
                                    error={formikInvoice.touched.address && Boolean(formikInvoice.errors.address)}
                                    helperText={formikInvoice.touched.address && formikInvoice.errors.address}
                                    inputProps={{ maxLength: 35 }}
                                />
                            </Stack>}
                        {invoiceType && <Stack py={2} flexDirection={"row"} width={{ xs: '100%', md: '50%', xl: '33%' }}>
                            <Button size='large' variant="contained" color="primary" sx={{ width: { xs: '160px', lg: 'auto' }, alignSelf: 'end' }} type='submit' >
                                <Save sx={{ mr: 1 }} />{t("Save Changes")}
                            </Button>
                        </Stack>}

                    </form>
                </Card>
            </CustomTabPanel>

            {/* bank account personal */}
            <CustomTabPanel value={value} index={
                ((userRole === "b2bInternRep" && userType === "individual") || (userRole === "internIFManager" && userType === "individual")) ? 2 : -1
            }>
                <Card style={{ padding: '20px', margin: 0 }}>
                    <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can add your bank account here for withdrawal transactions. The account must be in the currency of US Dollars (USD).")}</Typography><br /><br />
                    <form onSubmit={formikBank.handleSubmit}>
                        <Stack gap={2} p={2} flexDirection={{ xs: "column" }} width={{ xs: '100%', md: '50%', xl: '33%' }}>
                            <TextField
                                fullWidth
                                name="id"
                                label={t("ID")}
                                variant="outlined"
                                value={formikBank.values.id}
                                onChange={formikBank.handleChange}
                                error={formikBank.touched.id && Boolean(formikBank.errors.id)}
                                helperText={formikBank.touched.id && formikBank.errors.id}
                                inputProps={{ maxLength: 35 }}
                            />
                            <TextField
                                fullWidth
                                name="bankName"
                                label={t("Bank Name")}
                                variant="outlined"
                                value={formikBank.values.bankName}
                                onChange={formikBank.handleChange}
                                error={formikBank.touched.bankName && Boolean(formikBank.errors.bankName)}
                                helperText={formikBank.touched.bankName && formikBank.errors.bankName}
                                inputProps={{ maxLength: 35 }}
                            />
                            <TextField
                                fullWidth
                                name="branchCode"
                                label={t("Branch Code")}
                                placeholder='Branch Code of the Bank'
                                variant="outlined"
                                value={formikBank.values.branchCode}
                                onChange={formikBank.handleChange}
                                error={formikBank.touched.branchCode && Boolean(formikBank.errors.branchCode)}
                                helperText={formikBank.touched.branchCode && formikBank.errors.branchCode}
                                inputProps={{ maxLength: 35 }}
                            />
                            <TextField
                                fullWidth
                                name="iban"
                                label={t("IBAN")}
                                variant="outlined"
                                placeholder='IBAN of the Bank Account'
                                value={formikBank.values.iban}
                                onChange={formikBank.handleChange}
                                error={formikBank.touched.iban && Boolean(formikBank.errors.iban)}
                                helperText={formikBank.touched.iban && formikBank.errors.iban}
                                inputProps={{ maxLength: 35 }}
                            />
                            <TextField
                                fullWidth
                                name="routingNumber"
                                label={t("Routing Number")}
                                variant="outlined"
                                placeholder='US Routing Number of the Bank Account'
                                value={formikBank.values.routingNumber}
                                onChange={formikBank.handleChange}
                                error={formikBank.touched.routingNumber && Boolean(formikBank.errors.routingNumber)}
                                helperText={formikBank.touched.routingNumber && formikBank.errors.routingNumber}
                                inputProps={{ maxLength: 35 }}
                            />
                            <TextField
                                fullWidth
                                name="swiftOrBICCode"
                                label={t("Swift or BIC Code")}
                                variant="outlined"
                                placeholder='SWIFT or BIC Code of the Bank Account'
                                value={formikBank.values.swiftOrBICCode}
                                onChange={formikBank.handleChange}
                                error={formikBank.touched.swiftOrBICCode && Boolean(formikBank.errors.swiftOrBICCode)}
                                helperText={formikBank.touched.swiftOrBICCode && formikBank.errors.swiftOrBICCode}
                                inputProps={{ maxLength: 35 }}
                            />
                        </Stack>
                        <Stack p={2} flexDirection={"row"} width={{ xs: '100%', md: '50%', xl: '33%' }}>
                            <Button size='large' variant="contained" color="primary" sx={{ width: { xs: '160px', lg: 'auto' }, alignSelf: 'end' }} type='submit' >
                                <Save sx={{ mr: 1 }} />{t("Save Changes")}
                            </Button>
                        </Stack>

                    </form>
                </Card>
            </CustomTabPanel>

            {/* bank account business */}
            <CustomTabPanel value={value} index={
                (userRole === "b2bSalesRep" || userRole === "b2bCompanyRep" || (userRole === "b2bInternRep" && userType === "company") || (userRole === "internIFManager" && userType === "company")) ? 2 : -1
            }>
                <Card style={{ padding: '20px', margin: 0 }}>
                    <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can add your bank account here for withdrawal transactions. The account must be in the currency of US Dollars (USD).")}</Typography><br /><br />
                    <form onSubmit={formikBank.handleSubmit}>
                        <Stack gap={2} p={2} flexDirection={{ xs: "column" }} width={{ xs: '100%', md: '50%', xl: '33%' }}>
                            <TextField
                                fullWidth
                                name="accountName"
                                label={t("Account Name")}
                                variant="outlined"
                                value={formikBank.values.accountName}
                                onChange={formikBank.handleChange}
                                onBlur={formikBank.handleBlur}
                                error={formikBank.touched.accountName && Boolean(formikBank.errors.accountName)}
                                helperText={formikBank.touched.accountName && formikBank.errors.accountName}
                                inputProps={{ maxLength: 35 }}
                            />
                            <TextField
                                fullWidth
                                name="bankName"
                                label={t("Bank Name")}
                                variant="outlined"
                                value={formikBank.values.bankName}
                                onBlur={formikBank.handleBlur}
                                onChange={formikBank.handleChange}
                                error={formikBank.touched.bankName && Boolean(formikBank.errors.bankName)}
                                helperText={formikBank.touched.bankName && formikBank.errors.bankName}
                                inputProps={{ maxLength: 35 }}
                            />
                            <TextField
                                fullWidth
                                name="branchCode"
                                label={t("Branch Code")}
                                placeholder='Branch Code of the Bank'
                                onBlur={formikBank.handleBlur}
                                variant="outlined"
                                value={formikBank.values.branchCode}
                                onChange={formikBank.handleChange}
                                error={formikBank.touched.branchCode && Boolean(formikBank.errors.branchCode)}
                                helperText={formikBank.touched.branchCode && formikBank.errors.branchCode}
                                inputProps={{ maxLength: 35 }}
                            />
                            <TextField
                                fullWidth
                                name="iban"
                                label={t("IBAN")}
                                variant="outlined"
                                onBlur={formikBank.handleBlur}
                                placeholder='IBAN of the Bank Account'
                                value={formikBank.values.iban}
                                onChange={formikBank.handleChange}
                                error={formikBank.touched.iban && Boolean(formikBank.errors.iban)}
                                helperText={formikBank.touched.iban && formikBank.errors.iban}
                                inputProps={{ maxLength: 35 }}
                            />
                            <TextField
                                fullWidth
                                name="routingNumber"
                                label={t("Routing Number")}
                                variant="outlined"
                                onBlur={formikBank.handleBlur}
                                placeholder='US Routing Number of the Bank Account'
                                value={formikBank.values.routingNumber}
                                onChange={formikBank.handleChange}
                                error={formikBank.touched.routingNumber && Boolean(formikBank.errors.routingNumber)}
                                helperText={formikBank.touched.routingNumber && formikBank.errors.routingNumber}
                                inputProps={{ maxLength: 35 }}
                            />
                            <TextField
                                fullWidth
                                name="swiftOrBICCode"
                                label={t("Swift or BIC Code")}
                                variant="outlined"
                                onBlur={formikBank.handleBlur}
                                placeholder='SWIFT or BIC Code of the Bank Account'
                                value={formikBank.values.swiftOrBICCode}
                                onChange={formikBank.handleChange}
                                error={formikBank.touched.swiftOrBICCode && Boolean(formikBank.errors.swiftOrBICCode)}
                                helperText={formikBank.touched.swiftOrBICCode && formikBank.errors.swiftOrBICCode}
                                inputProps={{ maxLength: 35 }}
                            />
                        </Stack>
                        <Stack p={2} flexDirection={"row"} width={{ xs: '100%', md: '50%', xl: '33%' }}>
                            <Button size='large' variant="contained" color="primary" sx={{ width: { xs: '160px', lg: 'auto' }, alignSelf: 'end' }} type='submit' >
                                <Save sx={{ mr: 1 }} />{t("Save Changes")}
                            </Button>
                        </Stack>

                    </form>
                </Card>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
                <Typography>{t("Policies")}</Typography>
            </CustomTabPanel>
        </Box >

        <Dialog open={openTwoFactor} onClose={() => setOpenTwoFactor(false)}>
            <DialogTitle>{t("Two Factor Authentication")}</DialogTitle>
            <DialogContent>
                <Typography >{t("Your two factor auth changes will be saved. Do you confirm?")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenTwoFactor(false)} color="primary" variant={"outlined"}>
                    {t("Cancel")}
                </Button>
                <Button onClick={() => handleUpdate2fa()} color="primary" autoFocus variant='contained'>
                    {t("Confirm")}
                </Button>
            </DialogActions>

        </Dialog>

        <Dialog open={openBankAccountAccept} onClose={() => setOpenBankAccountAccept(false)}>
            <DialogTitle>{t("Bank Account")}</DialogTitle>
            <DialogContent>
                <Typography >{t("Attention! The account must be in the currency of US Dollars (USD).")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenBankAccountAccept(false)} color="primary" variant={"outlined"}>
                    {t("Cancel")}
                </Button>
                <Button onClick={() => {
                    handleSubmitBank()
                    setOpenBankAccountAccept(false)
                }}
                    color="primary" autoFocus variant='contained'>
                    {t("Confirm")}
                </Button>
            </DialogActions>
        </Dialog>

        
    </BizLayout >

}

export default Settings

