import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BizLayout from "layouts/biz/BizLayout"
import {
    Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, CircularProgress, Pagination, InputAdornment
} from '@mui/material'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BronzeMedal, SilverMedal, GoldMedal, Vip } from "assets"
import { useSnackbar } from 'notistack';
import { internIFManager_registerFunc } from "context/auth/auth";
import { clientListFunc } from "context/internIFManager/client";
import { MedalBox } from "components";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';
import { internIFManagerOverviewFunc } from "context/internIFManager/common";
import {
    createCreditWithdrawalRequestFunc, withdrawalsFunc, user_packageHistoryOverviewFunc, user_revenueHistoryForCreditFunc,
    user_registeredUsersCreditOverviewFunc, user_revenueHistoryForPackageFunc
} from "context/common/packages";
import moment from "moment";
import Label from 'src/components/label/label';
import Employee from 'assets/images/employee.png';

const info = [
    "Backendden sonra hepsi yapılmaya başlanacaktır."
]

const validationSchema = Yup.object({
    amount: Yup.number().min(1000, "Minimum $1000").required("Required"),
});

const pageSize = 10;

const Finance = () => {

    const [open, setOpen] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken, internIFManager_registerLoading } = useSelector((state) => state.auth);
    const { clientListData, clientListCount, clientsOverviewData } = useSelector((state) => state.client);
    const { withdrawalsData, withdrawalsCount, userBillingInfoCreditData: credits, createCreditWithdrawalRequestData, userBillingInfoData: billingInfo,
        user_packageHistoryOverviewData: packageOverview
    } = useSelector((state) => state.packages);
    const { user_revenueHistoryForCreditData: revenueForCreditData, user_revenueHistoryForCreditCount: revenueForCreditCount,
        user_revenueHistoryForPackageData: revenueForPackageData, user_revenueHistoryForPackageCount: revenueForPackageCount,
        user_registeredUsersCreditOverviewData: creditOverviewData } = useSelector((state) => state.packages);
    const [pageCredits, setPageCredits] = useState(1);
    const [pagePackages, setPagePackages] = useState(1);
    const [pagePendings, setPagePendings] = useState(1);
    const [pageNotPending, setPageNotPending] = useState(1);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const pendingWithdrawals = withdrawalsData?.filter((item) => item.status === 'pending');
    const notPendingWithdrawals = withdrawalsData?.filter((item) => item.status !== 'pending');

    console.log(billingInfo)
    const handleChange = (event, value) => {
        setPageCredits(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenHistory = () => {
        setOpenHistory(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenHistory(false);
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            amount: '',
            accessToken: accessToken
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values)
            dispatch(createCreditWithdrawalRequestFunc(values)).then((response) => {
                console.log(response)
                if (response?.payload?.data?.user_createCreditWithdrawalRequest?._id) {
                    enqueueSnackbar(t("Withdrawal request is created."), { variant: 'success' });
                    handleClose();
                } else {
                    enqueueSnackbar(response?.error?.message ?? 'Check your balance', { variant: 'error' });
                }
            });
        },
    });

    useEffect(() => {
        dispatch(internIFManagerOverviewFunc({ accessToken: accessToken }));
        dispatch(clientListFunc({ accessToken: accessToken, page: pageCredits, pageSize: pageSize }));
        dispatch(withdrawalsFunc({ accessToken: accessToken }));
        dispatch(user_packageHistoryOverviewFunc({ accessToken: accessToken }));
        dispatch(user_revenueHistoryForCreditFunc({ accessToken: accessToken }));
        dispatch(user_registeredUsersCreditOverviewFunc({ accessToken: accessToken }));
        dispatch(user_revenueHistoryForPackageFunc({ accessToken: accessToken }));
    }, [dispatch, accessToken, pageCredits, createCreditWithdrawalRequestData]);

    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }} >

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Finance")}</Typography>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent={"end"}>
                    <Button onClick={() => setOpenHistory(true)} style={{ marginRight: 20 }}
                        variant="outlined" color="primary" size="large"> <HistoryIcon color="white"
                            fontSize="medium" style={{ marginRight: 10 }} />
                        {t("History")}
                    </Button>
                    <Button onClick={handleClickOpen}
                        variant="contained" color="primary" size="large"> <CurrencyExchangeIcon color="white"
                            fontSize="medium" style={{ marginRight: 10 }} />
                        {t("Withdraw Money")}
                    </Button>

                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={4} md={3}>
                    <MedalBox
                        medalType={t("Standart Package")}
                        letterSpace={1}
                        bgColor="#FFEFE2"
                        count={packageOverview?.standard || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MedalBox
                        medalType={t("Pro Package")}
                        letterSpace={1}
                        bgColor="#F5F5F5"
                        count={packageOverview?.pro || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MedalBox
                        medalType={t("Special Package")}
                        bgColor="#fff1ba"
                        letterSpace={1}
                        count={packageOverview?.special || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MedalBox
                        medalType={t("Purchased Credits")}
                        bgColor="#383e42"
                        letterSpace={1}
                        count={creditOverviewData?.purchasedCredit || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Total Sales")}
                        bgColor="#e3e3e3"
                        letterSpace={1}
                        count={billingInfo?.revenue || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Withdrawn")}
                        bgColor="#e3e3e3"
                        letterSpace={1}
                        count={billingInfo?.withdrawn || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MedalBox
                        medalType={t("Withdrawable")}
                        bgColor="#e3e3e3"
                        letterSpace={1}
                        count={billingInfo?.credit?.credit?.withdrawable || 0}
                    />
                </Grid>
            </Grid>

            {pendingWithdrawals?.length > 0 && <>
                <Grid container spacing={2} mt={3} alignItems="center">
                    <Grid item xs={6} >
                        <Typography variant="h5">{t("Pending Withdrawals")}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={0} mt={3} sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: 1,
                    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
                }} component={Paper} >
                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{t("Request Date")}</TableCell>
                                    <TableCell align="center">{t("Amount")} ($)</TableCell>
                                    <TableCell align="center">{t("Status")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pendingWithdrawals?.map((item, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}      >
                                        <TableCell >{pagePendings === 1 ? index + 1 : (pagePendings - 1) * pageSize + index + 1}</TableCell>
                                        <TableCell align="left">{moment(item.createdAt).format("DD MMM YYYY hh:mm a")}</TableCell>
                                        <TableCell align="center">$ {item.paymentAmount?.amount}</TableCell>
                                        <TableCell align="center">
                                            {<Label
                                                color={item.status === 'pending' ? 'warning' :
                                                    item.status === 'evaluating' ? 'info' :
                                                        item.status === 'rejected' ? 'error' :
                                                            item.status === 'paymentpending' ? 'info' :
                                                                item.status === 'paymentFailed' ? 'error' :
                                                                    item.status === 'paymentSuccessful' ? 'success' : 'info'} >
                                                {item.status === 'pending' ? 'Pending' :
                                                    item.status === 'evaluating' ? 'Evaluating' :
                                                        item.status === 'rejected' ? 'Rejected' :
                                                            item.status === 'paymentpending' ? 'Payment Pending' :
                                                                item.status === 'paymentFailed' ? 'Payment Failed' :
                                                                    item.status === 'paymentSuccessful' ? 'Payment Successful' : ' '}
                                            </Label>}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </>}

            <Grid container spacing={2} mt={3} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h5">{t("Credit List")}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={0} mt={3} sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
            }} component={Paper} >
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">{t("Company")}</TableCell>
                                <TableCell align="left">{t("Name Surname")}</TableCell>
                                <TableCell align="center">{t("Sales")} ($)</TableCell>
                                <TableCell align="center">{t("Medal")}</TableCell>
                                <TableCell align="center">{t("Revenue")} ($)</TableCell>
                                <TableCell align="center">{t("Date")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {revenueForCreditData?.length > 0 ?
                                revenueForCreditData.map((item, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}      >
                                        <TableCell component="th" scope="row">{pageCredits === 1 ? index + 1 : (pageCredits - 1) * pageSize + index + 1}</TableCell>
                                        <TableCell align="left" sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1, alignItems: 'center' }}><img src={item.withdrawableHistory?.buyerOrg?.displayImageUrl ?? Employee}
                                            alt="avatar" style={{ width: 30, height: 30, borderRadius: 50 }} />{item.withdrawableHistory?.buyerOrg?.displayName}</TableCell>
                                        <TableCell align="left">{item.withdrawableHistory?.buyerUser?.displayName}</TableCell>
                                        <TableCell align="center">$ {item?.relatedOrder?.price?.netTotal || item?.withdrawableHistory?.boughtAmount}</TableCell>
                                        <TableCell align="center">
                                            {<Label variant="filled" color={item.withdrawableHistory?.buyerMedal}>
                                                {item.withdrawableHistory?.buyerMedal === 'bronze' ? 'Bronze' :
                                                    item.withdrawableHistory?.buyerMedal === 'silver' ? 'Silver' :
                                                        item.withdrawableHistory?.buyerMedal === 'gold' ? 'Gold' :
                                                            item.withdrawableHistory?.buyerMedal === 'vip' ? 'VIP' : 'None'}
                                            </Label>}
                                        </TableCell>
                                        <TableCell align="center">$ {item.count}</TableCell>
                                        <TableCell align="center">{moment(item.createdAt).format("DD.MM.YYYY hh:mm a")}</TableCell>

                                    </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell colSpan={6} align="center">No data found</TableCell>
                                </TableRow>
                            }
                        </TableBody>

                    </Table>
                    <Box display="flex" justifyContent="center" p={2}>
                        {revenueForCreditCount > pageSize && <Pagination
                            count={Math.ceil(revenueForCreditCount / pageSize)}
                            page={pageCredits}
                            onChange={handleChange}
                            color="primary"
                            style={{ marginLeft: 10 }}
                        />}
                    </Box>
                </TableContainer>
            </Grid>
            <Box height={40} />

            <Grid container spacing={2} mt={0} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h5">{t("Package List")}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={0} mt={3} sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
            }} component={Paper} >
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">{t("Name Surname")}</TableCell>
                                <TableCell align="left">{t("Type")}</TableCell>
                                <TableCell align="center">{t("Sales")} ($)</TableCell>
                                <TableCell align="center">{t("Medal")}</TableCell>
                                <TableCell align="center">{t("Revenue")} ($)</TableCell>
                                <TableCell align="center">{t("Date")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {revenueForPackageData?.length > 0 ?
                                revenueForPackageData.map((item, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}      >
                                        <TableCell component="th" scope="row">{pagePackages === 1 ? index + 1 : (pagePackages - 1) * pageSize + index + 1}</TableCell>
                                        <TableCell align="left" sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1, alignItems: 'center' }}><img src={item.withdrawableHistory?.buyerOrg?.displayImageUrl ?? Employee}
                                            alt="avatar" style={{ width: 30, height: 30, borderRadius: 50 }} />{item.withdrawableHistory?.buyerOrg?.displayName}</TableCell>
                                        <TableCell align="left">{item.withdrawableHistory?.buyerUser?.displayName}</TableCell>
                                        <TableCell align="center">$ {item?.relatedOrder?.price?.netTotal || item?.withdrawableHistory?.boughtAmount}</TableCell>
                                        <TableCell align="center">
                                            {<Label variant="filled" color={item.withdrawableHistory?.buyerMedal}>
                                                {item.withdrawableHistory?.buyerMedal === 'bronze' ? 'Bronze' :
                                                    item.withdrawableHistory?.buyerMedal === 'silver' ? 'Silver' :
                                                        item.withdrawableHistory?.buyerMedal === 'gold' ? 'Gold' :
                                                            item.withdrawableHistory?.buyerMedal === 'vip' ? 'VIP' : 'None'}
                                            </Label>}
                                        </TableCell>
                                        <TableCell align="center">$ {item.count}</TableCell>
                                        <TableCell align="center">{moment(item.createdAt).format("DD.MM.YYYY hh:mm a")}</TableCell>
                                    </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell colSpan={6} align="center">No data found</TableCell>
                                </TableRow>
                            }
                        </TableBody>

                    </Table>
                    <Box display="flex" justifyContent="center" p={2}>
                        {revenueForPackageCount > pageSize && <Pagination
                            count={Math.ceil(revenueForPackageCount / pageSize)}
                            page={pagePackages}
                            onChange={handleChange}
                            color="primary"
                            style={{ marginLeft: 10 }}
                        />}
                    </Box>
                </TableContainer>
            </Grid>
            <Box height={40} />


        </Box>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={formik.handleSubmit} style={{ padding: 10 }}>
                <DialogTitle id="form-dialog-title">{t("Withdraw Money")}</DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        {t("Enter the amount you want to withdraw.")}<br /><br />
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="amount"
                        name="amount"
                        label={t("Amount") + " ($)"}
                        type="text"
                        fullWidth
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                        helperText={formik.touched.amount && formik.errors.amount}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        {t("Cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary"
                        onClick={formik.handleSubmit}
                    >
                        {t("Withdraw")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>


        <Dialog open={openHistory} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t("History")}</DialogTitle>
            <DialogContent sx={{ mb: 2 }}>
                <DialogContentText>
                    {t("You can view your past withdrawals.")}<br /><br />
                </DialogContentText>
                <Grid container spacing={0} mt={3} sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: 1,
                    mb: 2,
                    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
                }} component={Paper} >
                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 450 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{t("Request Date")}</TableCell>
                                    <TableCell align="center">{t("Amount")} ($)</TableCell>
                                    <TableCell align="center">{t("Status")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {notPendingWithdrawals?.length > 0 ?
                                    notPendingWithdrawals?.map((item, index) => (
                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}      >
                                            <TableCell >{pageNotPending === 1 ? index + 1 : (pageNotPending - 1) * pageSize + index + 1}</TableCell>
                                            <TableCell align="left">{moment(item.createdAt).format("DD MMM YYYY")}</TableCell>
                                            <TableCell align="center">$ {item.paymentAmount?.amount}</TableCell>
                                            <TableCell align="center">
                                                {<Label
                                                    color={item.status === 'pending' ? 'warning' :
                                                        item.status === 'evaluating' ? 'info' :
                                                            item.status === 'rejected' ? 'error' :
                                                                item.status === 'paymentpending' ? 'info' :
                                                                    item.status === 'paymentFailed' ? 'error' :
                                                                        item.status === 'paymentSuccessful' ? 'success' : 'info'} >
                                                    {item.status === 'pending' ? 'Pending' :
                                                        item.status === 'evaluating' ? 'Evaluating' :
                                                            item.status === 'rejected' ? 'Rejected' :
                                                                item.status === 'paymentpending' ? 'Payment Pending' :
                                                                    item.status === 'paymentFailed' ? 'Payment Failed' :
                                                                        item.status === 'paymentSuccessful' ? 'Payment Successful' : ' '}
                                                </Label>}
                                            </TableCell>
                                        </TableRow>
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">No data found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="outlined">
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    </BizLayout>
}

export default Finance