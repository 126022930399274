import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import auth from "./auth/auth";

import client from "./salesRep/client"
import university from "./salesRep/university"

import parameters from "./common/parameters";
import user from "./common/user";
import notes from "./common/notes";

import company from "./companyRep/company";

import intern from "./internRep/intern";

import packages from "./common/packages";

import salesRepForManager from "./internIFManager/salesRep";
import commonForManager from "./internIFManager/common";
import clientForManager from "./internIFManager/client"
import universityForManager from "./internIFManager/university"
import companyRepForManager from "./internIFManager/companyRep"
import companyForManager from "./internIFManager/company"
import internRepForManager from "./internIFManager/internRep"


const store = configureStore({
    reducer: {
        auth,
        client,
        university,
        parameters,
        user,
        company,
        intern,
        packages,
        notes,
        salesRepForManager,
        commonForManager,
        clientForManager,
        universityForManager,
        companyRepForManager,
        companyForManager,
        internRepForManager
    },
    middleware: [thunk],
    devTools: true
});


export default store;
