import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
  universityListData: null,
  universityListCount: null,
  universityListLoading: false,
  universityListError: null,
};


export const universityListFunc = createAsyncThunk("client/universityListFunc", async (values) => {

  var data1 = JSON.stringify({
      query: `query InternIFManager_universities($pagination: Pagination) {
        internIFManager_universities(pagination: $pagination) {
          data {
            _id
            displayName
            displayImageUrl
            medal
            organizationType
            totalSalesTo
            country {
              _id
              name
              code
              flagUrl
            }
            city {
              _id
              name
            }
            registeredBy {
              _id
              role
              displayName
              displayImageUrl
            }
            createdAt
            updatedAt
            universityAdmin {
              _id
              role
              displayName
              displayImageUrl
              contact {
                email
              }
            }
            universityPackageCounts {
              standard {
                total
                usable
              }
              pro {
                total
                usable
              }
              special {
                total
                usable
              }
            }
          }
        }
      }`,
      variables: {
        "pagination": {
          "page":  values?.page,
          "pageSize":  values?.pageSize
        }
      }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
      const data = res.data.data;
      return data;
  }).catch((err) => {
      return err;
  });

  const data = await response;
  return data;
});


const university = createSlice({
  name: "university",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(universityListFunc.pending, (state) => {
      state.universityListLoading = true;
    }
    );
    builder.addCase(universityListFunc.fulfilled, (state, action) => {
      state.universityListLoading = false;
      console.log(action.payload, "action.payload");
      state.universityListData = action.payload?.internIFManager_universities?.data;
      state.universityListCount = action.payload?.internIFManager_universities?.count;
      state.universityListError = action.payload.message;
    }
    );
    builder.addCase(universityListFunc.rejected, (state, action) => {
      state.universityListLoading = false;
      state.universityListError = action.error.message;
    }
    );
  }
});


export default university.reducer;