import { Route } from 'react-router-dom'
import Dashboard from 'pages/internifInternManager/Dashboard'
import Client from 'pages/internifInternManager/Client'
import University from 'pages/internifInternManager/University'
import Finance from 'pages/internifInternManager/Finance'
import Ticket from 'pages/internifInternManager/Ticket'
import Profile from 'pages/user/Profile'
import Settings from 'pages/user/Settings'
import Onboarding from 'pages/internifInternManager/Onboarding'
import Company from 'pages/internifInternManager/Company'
import Intern from 'pages/internifInternManager/Intern'
import Packages from 'pages/internifInternManager/Packages'


export const InternIFInternManagerDashboard = () => {

    return <>
        <Route path="/" element={<Dashboard />} />
        <Route path="/business-partner" element={<Client />} />
        <Route path="/university" element={<University />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/company" element={<Company />} />
        <Route path="/intern" element={<Intern />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="*" element={<Dashboard />} />
    </>
}


export const InternIFInternManagerOnboarding= () => {
    return <>
        <Route path="/" element={<Onboarding />} />
    </>
}