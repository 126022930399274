import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
  internIFManagerOverviewData: null,
  internIFManagerOverviewLoading: false,
  internIFManagerOverviewError: null,
  salesRepOverviewData: null,
  clientOverviewData: null,
  universityOverviewData: null,
  companyRepOverviewData: null,
  companyOverviewData: null,
  internRepOverviewData: null
};


export const internIFManagerOverviewFunc = createAsyncThunk("common/internIFManagerOverviewFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query SalesRepsOverview {
      internIFManager_dashboardOverviews {
        salesRepsOverview {
          bronzeCount
          silverCount
          goldCount
          vipCount
        }
        clientsOverview {
          bronzeCount
          silverCount
          goldCount
          vipCount
        }
        companyRepsOverview {
          bronzeCount
          silverCount
          goldCount
          vipCount
        }
        companiesOverview {
          bronzeCount
          silverCount
          goldCount
          vipCount
        }
        universitiesOverview {
          bronzeCount
          silverCount
          goldCount
          vipCount
        }
        internRepsOverview {
          bronzeCount
          silverCount
          goldCount
          vipCount
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});




const common = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(internIFManagerOverviewFunc.pending, (state) => {
      state.internIFManagerOverviewLoading = true;
    }
    );
    builder.addCase(internIFManagerOverviewFunc.fulfilled, (state, action) => {
      state.internIFManagerOverviewLoading = false;
      state.internIFManagerOverviewData = action.payload?.internIFManager_dashboardOverviews;
      state.salesRepOverviewData = action.payload?.internIFManager_dashboardOverviews?.salesRepsOverview;
      state.clientOverviewData = action.payload?.internIFManager_dashboardOverviews?.clientsOverview;
      state.universityOverviewData = action.payload?.internIFManager_dashboardOverviews?.universitiesOverview;
      state.companyRepOverviewData = action.payload?.internIFManager_dashboardOverviews?.companyRepsOverview;
      state.companyOverviewData = action.payload?.internIFManager_dashboardOverviews?.companiesOverview;
      state.internRepOverviewData = action.payload?.internIFManager_dashboardOverviews?.internRepsOverview;
      state.internIFManagerOverviewError = action.payload.message;
    }
    );
    builder.addCase(internIFManagerOverviewFunc.rejected, (state, action) => {
      state.internIFManagerOverviewLoading = false;
      state.internIFManagerOverviewError = action.error.message;
    }
    );
  }
});


export default common.reducer;