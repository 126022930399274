import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
  companyRepListData: null,
  companyRepListCount: null,
  companyRepListLoading: false,
  companyRepListError: null,
  companiesOfCompanyRepData: null,
  companiesOfCompanyRepCount: null,
  companiesOfCompanyRepLoading: false,
  companiesOfCompanyRepError: null,
};


export const companyRepListFunc = createAsyncThunk("companyRep/companyRepListFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Data($pagination: Pagination) {
      internIFManager_companyReps(pagination: $pagination) {
        count
        data {
          _id
          role
          displayName
          displayImageUrl
          contact {
            email
          }
          medal
          totalSalesAmount
          companyMedalOverview {
            vipCount
            silverCount
            goldCount
            bronzeCount
          }
        }
      }
    }`,
    variables: {
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const companiesOfCompanyRepFunc = createAsyncThunk("companyRep/companiesOfCompanyRepFunc", async (values) => {

  var data1 = JSON.stringify({

    query: `query Data($companyRepId: ID!, $pagination: Pagination) {
      internIFManager_companiesOfCompanyRep(companyRepId: $companyRepId, pagination: $pagination) {
        data {
          _id
          displayName
          displayImageUrl
          medal
          organizationType
          totalSalesTo
          country {
            _id
            name
            code
            flagUrl
          }
          city {
            _id
            name
          }
          registeredBy {
            _id
            role
            displayName
            displayImageUrl
          }
          createdAt
          updatedAt
          companyAdmin {
            _id
            role
            displayName
            displayImageUrl
            contact {
              email
            }
          }
          numberOfEmployee {
            _id
            name
          }
        }
      }
    } `,
    variables: {
      "companyRepId": values.companyRepId,
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }
  ).catch((err) => {
    return err;
  }
  );

  const data = await response;
  return data;
}
);







const companyRep = createSlice({
  name: "companyRep",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(companyRepListFunc.pending, (state) => {
      state.companyRepListLoading = true;
    }
    );
    builder.addCase(companyRepListFunc.fulfilled, (state, action) => {
      state.companyRepListLoading = false;
      console.log(action.payload, "action.payload");
      state.companyRepListData = action.payload?.internIFManager_companyReps?.data;
      state.companyRepListCount = action.payload?.internIFManager_companyReps?.count;
      state.companyRepListError = action.payload.message;
    }
    );
    builder.addCase(companyRepListFunc.rejected, (state, action) => {
      state.companyRepListLoading = false;
      state.companyRepListError = action.error.message;
    }
    );

    builder.addCase(companiesOfCompanyRepFunc.pending, (state) => {
      state.companiesOfCompanyRepLoading = true;
    }
    );
    builder.addCase(companiesOfCompanyRepFunc.fulfilled, (state, action) => {
      state.companiesOfCompanyRepLoading = false;
      console.log(action.payload, "action.payload");
      state.companiesOfCompanyRepData = action.payload?.internIFManager_companiesOfCompanyRep?.data;
      state.companiesOfCompanyRepCount = action.payload?.internIFManager_companiesOfCompanyRep?.count;
      state.companiesOfCompanyRepError = action.payload.message;
    }
    );
    builder.addCase(companiesOfCompanyRepFunc.rejected, (state, action) => {
      state.companiesOfCompanyRepLoading = false;
      state.companiesOfCompanyRepError = action.error.message;
    }
    );
  }
});


export default companyRep.reducer;