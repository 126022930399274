import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
  clientListData: null,
  clientListCount: null,
  clientListLoading: false,
  clientListError: null,
};


export const clientListFunc = createAsyncThunk("client/clientListFunc", async (values) => {

  var data1 = JSON.stringify({
      query: `query InternIFManager_clients($pagination: Pagination) {
        internIFManager_clients(pagination: $pagination) {
            count
            data {
              _id
              displayName
              clientCompanyName
              clientPackageCounts {
                standard {
                  usable
                }
                pro {
                  usable
                }
                special {
                  usable
                }
              }
              totalSalesTo
      
              medal
            }
          }
        }`,
      variables: {
          "pagination": {
              "page": values.page,
              "pageSize": values.pageSize
          }
      }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
      const data = res.data.data;
      return data;
  }).catch((err) => {
      return err;
  });

  const data = await response;
  return data;
});


const client = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clientListFunc.pending, (state) => {
      state.clientListLoading = true;
    }
    );
    builder.addCase(clientListFunc.fulfilled, (state, action) => {
      state.clientListLoading = false;
      console.log(action.payload, "action.payload");
      state.clientListData = action.payload?.internIFManager_clients?.data;
      state.clientListCount = action.payload?.internIFManager_clients?.count;
      state.clientListError = action.payload.message;
    }
    );
    builder.addCase(clientListFunc.rejected, (state, action) => {
      state.clientListLoading = false;
      state.clientListError = action.error.message;
    }
    );
  }
});


export default client.reducer;