import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid, Box, Checkbox, Typography, FormControlLabel, Link, Dialog, DialogTitle,
    DialogContent, DialogActions, Button, CircularProgress
} from '@mui/material'
import AuthLayout from '../../layouts/auth/AuthLayout'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack'
import { AuthHead, BasicInputPassword, BlackButton } from 'components'
import { useNavigate } from 'react-router';
import { confirmationEmailFunc, resetPasswordFunc, confirmRegistirationByUserFunc } from 'context/auth/auth';
import { anonymous_policiesFunc } from 'context/common/user';
import parse from 'html-react-parser';
import { MuiOtpInput } from 'mui-one-time-password-input'
import regexPattern from 'src/utils/regexPattern';



const ValidationSchema = Yup.object({
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters!')
        .required('Password is required!')
        .matches(regexPattern.atLeastOnelowerCases, "Must contain lower case!")
        .matches(regexPattern.atLeastOneupperCases, "Must contain upper case!")
        .matches(regexPattern.atLeastOnenumbers, "Must contain number!")
        .matches(regexPattern.atLeastOnepecialCharacters, "Must contain special character!"),
    verifyPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match!')
        .required('Password is required!'),
    code: Yup.string().required('Code is required!'),
})


const CreateNewPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { signinLoading } = useSelector(state => state.auth);
    const { anonymous_policiesData: policies, anonymous_policiesLoading: policiesLoading } = useSelector(state => state.user);
    const [openDialog, setOpenDialog] = useState(false);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");
    const reset = urlSearchParams.get("reset");


    const handleClickOpen = (type) => () => {

        dispatch(anonymous_policiesFunc({ domain: "biz_internif", type: type }))

        if (type) {
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const { handleSubmit, handleChange, handleBlur, errors, values, touched, setFieldValue } = useFormik({
        initialValues: {
            verifyPassword: '', password: '', accept: false, code: ''
        },
        validationSchema: ValidationSchema,
        onSubmit: () => {
            console.log('submit', values)
            // dispatch(confirmationEmailFunc({
            //     token: token,
            //     code: values.code,
            // })).then((res) => {
            //     if (res?.payload?.isSuccess) {
            //         enqueueSnackbar('Password created successfully', { variant: 'success' })
            //         console.log("başarılı")
            //         dispatch(resetPasswordFunc({
            //             password: values.password,
            //             passwordToken: reset
            //         })).then((res) => {
            //             if (res?.payload?.isSuccess) {
            //                 enqueueSnackbar('Password created successfully', { variant: 'success' })
            //                 navigate('/signin')
            //             } else {
            //                 enqueueSnackbar('Password creation failed', { variant: 'error' })
            //             }
            //         }).catch((err) => {
            //             enqueueSnackbar('Password creation failed', { variant: 'error' })
            //         })
            //     } else if (res?.payload?.response?.status === 500) {
            //         if (res?.payload?.response?.data?.message === "RegistrationAlreadyConfirmed") {
            //             dispatch(resetPasswordFunc({
            //                 password: values.password,
            //                 passwordToken: reset
            //             })).then((res) => {
            //                 if (res?.payload?.isSuccess) {
            //                     enqueueSnackbar('Password created successfully', { variant: 'success' })
            //                     navigate('/signin')
            //                 } else {
            //                     enqueueSnackbar('Your password has already been created.', { variant: 'warning' })
            //                 }
            //             }).catch((err) => {
            //                 enqueueSnackbar('Your password has already been created.', { variant: 'warning' })
            //             })
            //         } else {
            //             enqueueSnackbar('Password creation failed', { variant: 'error' })
            //         }
            //     } else {
            //         enqueueSnackbar('Password creation failed', { variant: 'error' })
            //     }
            // }).catch((err) => {
            //     enqueueSnackbar('Password creation failed', { variant: 'error' })
            // })
            dispatch(confirmRegistirationByUserFunc({
                token: token,
                password: values.password,
                code: values.code,
                resetPasswordToken: reset
            })).then((res) => {
                if (res?.payload?.isSuccess) {
                    enqueueSnackbar('Password created successfully', { variant: 'success' })
                    window.location.href = 'https://manage.internif.com'
                }
                else if (res?.payload?.response?.data?.message === "RegistrationAlreadyConfirmed") {
                    enqueueSnackbar('Your password has already been created.', { variant: 'warning' })

                } else {
                    enqueueSnackbar('Password creation failed', { variant: 'error' })
                }

            }).catch((err) => {
                enqueueSnackbar('Password creation failed', { variant: 'error' })
            })

        }
    })





    return <AuthLayout>
        <Grid item xs={11} sm={9} md={6} lg={5} xl={4} justifyContent="center" alignItems="center" flexDirection={"column"}

        >

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <AuthHead title="Create New Password" description="You can login to the panel by creating your new password." />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" marginTop="30px">

            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" width="100%">
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <Box flexDirection="column" alignItems="center" gap="10px" width="100%"
                    >

                        <Typography fontSize={"15px"} fontFamily={'Causten-Bold'} color="primary.500">
                            Code
                        </Typography>

                        <MuiOtpInput
                            value={values.code}
                            onChange={(code) => setFieldValue('code', code)}
                            length={6}
                            style={{
                                width: '100%', borderColor: '#000',
                                gap: '10px',
                                marginTop: 5, marginBottom: 20,
                                '& #:rb:': {
                                    backgroundColor: 'red',
                                }
                            }}
                        />

                        <BasicInputPassword
                            name="password"
                            type="password"
                            label="Password"
                            placeholder="Enter your password"
                            value={values?.password}
                            error={touched.password && errors.password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                        <BasicInputPassword
                            name="verifyPassword"
                            type="password"
                            label="Verify Password"
                            placeholder="Reenter your password"
                            value={values?.verifyPassword}
                            error={touched.verifyPassword && errors.verifyPassword}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />

                        <Box display="flex" justifyContent="space-between" alignItems={'center'} width="100%" mb={1}>
                            <FormControlLabel
                                control={<Checkbox
                                    color="primary"
                                    checked={values?.accept}
                                    onChange={handleChange}
                                    name="accept"
                                />}
                                label={<Typography variant="h8" color="primary.main">
                                    I’ve read and accept the <Link component="span" onClick={handleClickOpen("cookies_policy")} color="info.main" variant="h8">
                                        Cookies Policy
                                    </Link>, <Link component="span" onClick={handleClickOpen("terms_and_conditions")} color="info.main" variant="h8">
                                        Terms and Conditions
                                    </Link> and <Link component="span" onClick={handleClickOpen("privacy_policy")} color="info.main" variant="h8">
                                        Privacy Policy</Link>  of InternIF.
                                </Typography>}
                            />
                        </Box>


                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%">
                            <BlackButton
                                name="Create Password"
                                isActive={values?.verifyPassword && values?.password && !errors?.verifyPassword
                                    && !errors?.password && values?.accept && values?.code && !errors?.code}
                                handleSubmit={handleSubmit}
                                loading={signinLoading}
                            />
                        </Box>



                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%" mt={"15px"} mb={"30px"}>
                            <Typography variant="h7" color="primary.300">
                                Do you have an account? <a href="/signin" style={{ textDecoration: 'none' }}><Typography variant="h7" color="info.main" ml={"5px"}>
                                    Sign In
                                </Typography>
                                </a>
                            </Typography>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Grid>

        <Dialog
            open={openDialog}
            onClose={handleClose}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogContent dividers={true} sx={{ minWidth: 'md', width: '500px', mt: 4 }}>
                {policiesLoading
                    ? <CircularProgress />
                    : parse(policies?.data?.[0]?.content?.tr ?? '')}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    </AuthLayout >
}

export default CreateNewPassword