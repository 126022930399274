import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";


const initialState = {
    updateUserData: null,
    updateUserLoading: false,
    updateUserError: null,
    getUserData: null,
    getUserLoading: false,
    getUserError: null,
    getProfilePicturePresignedUrlData: null,
    getProfilePicturePresignedUrlLoading: false,
    getProfilePicturePresignedUrlError: null,
    updateInHouseUserData: null,
    updateInHouseUserLoading: false,
    updateInHouseUserError: null,
    getInHouseUserData: null,
    getInHouseUserLoading: false,
    getInHouseUserError: null,
    updateDefaultLanguageData: null,
    updateDefaultLanguageLoading: false,
    updateDefaultLanguageError: null,
    getDefaultLanguageData: null,
    getDefaultLanguageLoading: false,
    getDefaultLanguageError: null,
    onboarded: null,
    autoInfo: false,
    userType: null,
    anonymous_policiesData: null,
    anonymous_policiesLoading: false,
    anonymous_policiesError: null,
};

export const getUserFunc = createAsyncThunk("user/getUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Self_inHouseUser {
            self_inHouseUser {
              _id
              role
              inHouseOnboardingStatus
              personalInformation {
                address
                name
                surname
                birthDate
                language {
                    _id
                }
                languageLevel {
                    _id
                }
                country {
                  _id
                }
                city {
                  _id
                }
                gender
                nationality{
                    _id
                }
                phoneNumber
                profileImageUrl
                backgroundImageUrl
              }
              contact {
                phoneNumber
                phoneNumberVerified
                email
                emailVerified
              }
            }
          }
            `
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const updateUserFunc = createAsyncThunk("user/updateUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation Self_updateUser(
            $updateInput: UpdateInHouseInput!
            $status: InHouseUserOnboardingStatus!
          ) {
            internIFUser_updateInHouseUser(updateInput: $updateInput) {
              _id
            },
            internIFUser_updateInHouseUserOnboardingStatus(status: $status) {
                success
              }
          }`,
        variables: {
            "updateInput": {
                "personalInformation": {
                    "address": values.address,
                    "name": values.name,
                    "surname": values.surname,
                    "birthDate": values.birthDate,
                    "country": values.countryId && {
                        "_id": values.countryId
                    },
                    "city": values.cityId && {
                        "_id": values.cityId
                    },
                    "nationality": values.nationality && {
                        "_id": values.nationality
                    },
                    "language": {
                        "_id": values.language || "6496f358dbeaffe2df3be742"
                    },
                    "languageLevel": {
                        "_id": values.languageLevel || "6496f359dbeaffe2df3be7d2"
                    },
                    "gender": values.gender,
                    "phoneNumber": values.phoneNumber,
                    "profileImageUrl": values.profileImageUrl
                },
                "contact": {
                    "phoneNumber": values.phoneNumber,
                    "phoneNumberVerified": values.phoneNumberVerified,
                    "email": values.email,
                    "emailVerified": values.emailVerified
                },
                "inHouseOrganization": {
                    "inHouseOrganizationType": values.type,
                    "company": values.type === "company" ? {
                        "name": values.companyName,
                        "inHouseCompanyType": values.companyType,
                        "logoImageUrl": values.companyLogoImageUrl,
                    } : null,
                }
            },
            "status": "completed"
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const getProfilePicturePresignedUrlFunc = createAsyncThunk("user/getProfilePicturePresignedUrlFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query UserPresignedUrls($contentType: ImageContentType!) {
            userPresignedUrls(contentType: $contentType) {
              profileImage {
                presignedUploadUrl
                url
              }
              backgroundImage {
                presignedUploadUrl
                url
              }
            }
          }`,
        variables: {
            "contentType": "jpegImage"
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/dms/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const updateInHouseUserFunc = createAsyncThunk("user/updateInHouseUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation Self_updateUser($updateInput: UpdateInHouseInput!) {
            internIFUser_updateInHouseUser(updateInput: $updateInput) {
              _id
            }
          }
            `,
        variables: {
            "updateInput": {
                "inHouseOrganization": {
                    "inHouseOrganizationType": values.inHouseOrganizationType,
                    "company": values.inHouseOrganizationType === "company" ? {
                        "name": values.companyName,
                        "inHouseCompanyType": values.inHouseCompanyType,
                        "logoImageUrl": values.logoImageUrl,
                    } : null,
                    "university": values.inHouseOrganizationType === "university" ? {
                        "name": values.universityName,
                        "logoImageUrl": values.logoImageUrl,
                    } : null
                }
            }
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const getInHouseUserFunc = createAsyncThunk("user/getInHouseUserFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query InternIFUser_inHouseUsers {
            self_inHouseUser {
              inHouseOrganization {     
                 inHouseOrganizationType
                company {
                  name
                  inHouseCompanyType
                  logoImageUrl
                }
                university {
                  name
                  logoImageUrl
                }
              }
            }
          } `,
    });


    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const updateDefaultLanguageFunc = createAsyncThunk("user/updateDefaultLanguageFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `mutation Self_updateUser($updateUserInput: UpdateUserInput!) {
            self_updateUser(updateUserInput: $updateUserInput) {
              _id
            }
          }`,
        variables: {
            "updateUserInput": {
                "settings": {
                    "defaultLanguage": values.language
                }
            }
        }
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const getDefaultLanguageFunc = createAsyncThunk("user/getDefaultLanguageFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Self_inHouseUser {
            self_user {
              settings {
                defaultLanguage
              }
            }
          }`,
    });


    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


export const anonymous_policiesFunc = createAsyncThunk("user/anonymous_policiesFunc", async (values) => {

    const data1 = JSON.stringify({
        query: `query Anonymous_policies($filter: FilterPolicyInput) {
        anonymous_policies(filter: $filter) {
          data {
            _id
            name
            description
            domain
            content {
              tr
              en
            }
            target
            required
            targetRoles
            type
            version
            versionCount
            updatedAt
            createdAt
          }
        }
      }`,
        variables: {
            "filter": {
                "domain": values?.domain,
                "type": values?.type,
                "target": values?.target,
            }
        }

    });


    const config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});





const user = createSlice({
    name: "user",
    initialState,
    reducers: {
        setAutoInfo: (state, action) => {
            state.autoInfo = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getUserFunc.pending, (state) => {
            state.getUserLoading = true;
        });
        builder.addCase(getUserFunc.fulfilled, (state, action) => {
            state.getUserLoading = false;
            state.getUserData = action.payload?.self_inHouseUser;
            state.onboarded = action.payload?.self_inHouseUser?.inHouseOnboardingStatus;
            console.log(action.payload)
            state.getUserError = action.payload.message;
        });
        builder.addCase(getUserFunc.rejected, (state, action) => {
            state.getUserLoading = false;
            state.getUserError = action.error.message;
        });

        builder.addCase(updateUserFunc.pending, (state) => {
            state.updateUserLoading = true;
        });
        builder.addCase(updateUserFunc.fulfilled, (state, action) => {
            state.updateUserLoading = false;
            state.updateUserData = action.payload?.self_updateUser?._id;
            console.log(action.payload);
            state.updateUserError = action.payload.message;
        });
        builder.addCase(updateUserFunc.rejected, (state, action) => {
            state.updateUserLoading = false;
            state.updateUserError = action.error.message;
        });

        builder.addCase(getProfilePicturePresignedUrlFunc.pending, (state) => {
            state.getProfilePicturePresignedUrlLoading = true;
        });
        builder.addCase(getProfilePicturePresignedUrlFunc.fulfilled, (state, action) => {
            state.getProfilePicturePresignedUrlLoading = false;
            state.getProfilePicturePresignedUrlData = action.payload?.userPresignedUrls?.profileImage;
            state.getProfilePicturePresignedUrlError = action.payload.message;
        });
        builder.addCase(getProfilePicturePresignedUrlFunc.rejected, (state, action) => {
            state.getProfilePicturePresignedUrlLoading = false;
            state.getProfilePicturePresignedUrlError = action.error.message;
        });

        builder.addCase(updateInHouseUserFunc.pending, (state) => {
            state.updateInHouseUserLoading = true;
        });
        builder.addCase(updateInHouseUserFunc.fulfilled, (state, action) => {
            state.updateInHouseUserLoading = false;
            state.updateInHouseUserData = action.payload?.internIFUser_updateInHouseUser?._id;
            state.updateInHouseUserError = action.payload.message;
        });
        builder.addCase(updateInHouseUserFunc.rejected, (state, action) => {
            state.updateInHouseUserLoading = false;
            state.updateInHouseUserError = action.error.message;
        });

        builder.addCase(getInHouseUserFunc.pending, (state) => {
            state.getInHouseUserLoading = true;
        });
        builder.addCase(getInHouseUserFunc.fulfilled, (state, action) => {
            state.getInHouseUserLoading = false;
            state.getInHouseUserData = action.payload?.self_inHouseUser;
            state.userType = action.payload?.self_inHouseUser?.inHouseOrganization?.inHouseOrganizationType;
            state.getInHouseUserError = action.payload.message;
        });
        builder.addCase(getInHouseUserFunc.rejected, (state, action) => {
            state.getInHouseUserLoading = false;
            state.getInHouseUserError = action.error.message;
        });

        builder.addCase(updateDefaultLanguageFunc.pending, (state) => {
            state.updateDefaultLanguageLoading = true;
        });
        builder.addCase(updateDefaultLanguageFunc.fulfilled, (state, action) => {
            state.updateDefaultLanguageLoading = false;
            state.updateDefaultLanguageData = action.payload?.self_updateUser;
            state.updateDefaultLanguageError = action.payload.message;
        });
        builder.addCase(updateDefaultLanguageFunc.rejected, (state, action) => {
            state.updateDefaultLanguageLoading = false;
            state.updateDefaultLanguageError = action.error.message;
        });

        builder.addCase(getDefaultLanguageFunc.pending, (state) => {
            state.getDefaultLanguageLoading = true;
        });
        builder.addCase(getDefaultLanguageFunc.fulfilled, (state, action) => {
            state.getDefaultLanguageLoading = false;
            state.getDefaultLanguageData = action.payload?.self_user?.settings?.defaultLanguage;
            state.getDefaultLanguageError = action.payload.message;
        });
        builder.addCase(getDefaultLanguageFunc.rejected, (state, action) => {
            state.getDefaultLanguageLoading = false;
            state.getDefaultLanguageError = action.error.message;
        });

        builder.addCase(anonymous_policiesFunc.pending, (state) => {
            state.anonymous_policiesLoading = true;
        });
        builder.addCase(anonymous_policiesFunc.fulfilled, (state, action) => {
            state.anonymous_policiesLoading = false;
            state.anonymous_policiesData = action.payload?.anonymous_policies;
            state.anonymous_policiesError = action.payload.message;
        });
        builder.addCase(anonymous_policiesFunc.rejected, (state, action) => {
            state.anonymous_policiesLoading = false;
            state.anonymous_policiesError = action.error.message;
        });
    }
});

export const { setAutoInfo } = user.actions;

export default user.reducer;