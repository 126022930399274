import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box, Grid, Stack, Menu, MenuItem, Avatar, IconButton,
    AppBar, Toolbar, Drawer, Typography, Badge
} from "@mui/material";
import { useResponsive } from "hooks/use-responsive"
import { InternifLogoWithText, InternifLogoOnly } from "assets";
import { NavSectionVertical } from "src/components/nav-section";
import { useTranslation } from 'react-i18next';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import SchoolIcon from '@mui/icons-material/School';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from '@mui/icons-material/AccountCircle';
import Settings from '@mui/icons-material/Settings';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Groups2Icon from '@mui/icons-material/Groups2';
import StyleIcon from '@mui/icons-material/Style';
import { PageOpening, InformationDrawer } from "components";
import { removeUser } from "context/auth/auth";
import InternIFLogo from "assets/logos/InternIFLogo.png";
import { useNavigate } from "react-router";
import userImage from 'assets/images/common/user.png';
import { Language, Notifications } from "@mui/icons-material";
import NotificationsPopover from "src/components/notifications-popover";
import LanguagePopover from "src/components/language-popover";

const BizLayout = ({ children, info }) => {

    const lgUp = useResponsive('up', 'lg');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getUserData } = useSelector((state) => state.user);
    const { userRole } = useSelector((state) => state.auth);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        navigate('/profile');
        handleClose();
        setAnchorEl(null);
    };

    const handleZ = () => {
        navigate('/settings');
        handleClose();
        setAnchorEl(null);
    };

    const handleLogout = () => {

        handleClose();
        dispatch(removeUser());
        navigate('/');

        setDrawerOpen(false);
    }

    console.log(userRole)

    return <Grid container spacing={2} sx={{ p: 2 }} >
        {process.env.REACT_APP_TEST === "true" && info && <InformationDrawer info={info} />}
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: 280 },
            }}
        >
            {lgUp ? (
                <Stack
                    sx={{
                        height: 1,
                        position: 'fixed',
                        backgroundColor: '#F9FBFD',
                        width: 280,
                        borderRight: () => `dashed 1px #dedede`,
                    }}
                    justifyContent="space-between"
                >
                    <Box>
                        <Box sx={{ mb: 2, mt: 4, ml: 4 }}>
                            <InternifLogoWithText />
                        </Box>
                        <NavSectionVertical
                            data={[{
                                subheader: t('Business Management'),
                                items:
                                    userRole === "internIFFinance" ? [
                                        { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                        { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                        { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                        { title: t('Intern'), path: "/intern", icon: <Groups2Icon /> },
                                        { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                        { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                        { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                        { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                    ] :
                                        userRole === "internIFMarketing" ? [
                                            { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                            { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                            { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                            { title: t('Intern'), path: "/intern", icon: <Groups2Icon /> },
                                            { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                            { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                            { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                            { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                        ] :
                                            userRole === "internIFTech" ? [
                                                { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                { title: t('Intern'), path: "/intern", icon: <Groups2Icon /> },
                                                { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                                { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                            ] :
                                                userRole === "internIFManager" ? [
                                                    { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                    { title: t('Agent'), path: "/agent", icon: <Groups2Icon /> },
                                                    { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                    { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                    { title: t('Company Agent'), path: "/company-agent", icon: <Groups2Icon /> },
                                                    { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                    { title: t('Intern Partner'), path: "/intern-agent", icon: <Groups2Icon /> },
                                                    { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                    { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                    { title: t('Notepad'), path: "/notepad", icon: <TextSnippetIcon /> },
                                                ] :
                                                    userRole === "internIFInternManager" ? [
                                                        { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                        { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                        { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                        { title: t('Intern'), path: "/intern", icon: <Groups2Icon /> },
                                                        { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                        { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                                        { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                        { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                    ] :
                                                        userRole === "internIFCustomerRep" ? [
                                                            { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                            { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                            { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                            { title: t('Intern'), path: "/intern", icon: <Groups2Icon /> },
                                                            { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                            { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                                            { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                            { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                        ] :
                                                            userRole === "b2bSalesRep" ? [
                                                                { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                                { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                                { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                                { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                                { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                            ] :
                                                                userRole === "b2bCompanyRep" ? [
                                                                    { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                                    { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                                    { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                                    { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> }
                                                                ] :
                                                                    userRole === "b2bInternRep" ? [
                                                                        { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                                        { title: t('Intern'), path: "/intern", icon: <Groups2Icon /> },
                                                                        { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                                        { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> }
                                                                    ] :
                                                                        userRole === "b2bClient" ? [
                                                                            { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                                            { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                                                            { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                                            { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                                        ] :
                                                                            [
                                                                                { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                                            ]
                            }]}
                        />
                    </Box>
                    <Box sx={{ mb: 5, mt: 4, mx: 1, cursor: 'pointer' }} flexDirection="row" display="flex" alignItems="center" bgcolor={'#f5f5f5'} p={1} borderRadius={2}
                        onClick={handleClick}
                    >
                        <Avatar sx={{ width: 40, height: 40 }} src={getUserData?.personalInformation?.profileImageUrl ?
                            getUserData?.personalInformation?.profileImageUrl : userImage} />
                        <Box sx={{ ml: 2 }}>
                            <Box sx={{ fontWeight: 600 }}>{getUserData?.personalInformation?.name} {getUserData?.personalInformation?.surname}</Box>
                            <Box sx={{ fontSize: 12 }}>{
                                getUserData?.role === 'internIFFinance' ? t("InternIF Finance") :
                                    getUserData?.role === 'internIFMarketing' ? t("InternIF Marketing") :
                                        getUserData?.role === 'internIFTech' ? t("InternIF Tech") :
                                            getUserData?.role === 'internIFManager' ? t("InternIF Manager") :
                                                getUserData?.role === 'internIFInternManager' ? t("InternIF Intern Manager") :
                                                    getUserData?.role === 'internIFCustomerRep' ? t("Customer Representative") :
                                                        getUserData?.role === 'b2bSalesRep' ? t("Agent") :
                                                            getUserData?.role === 'b2bCompanyRep' ? t('Company Agent') :
                                                                getUserData?.role === 'b2bInternRep' ? t('Intern Partner') :
                                                                    getUserData?.role === 'b2bClient' ? t('Business Partner') :
                                                                        null} </Box>
                        </Box>

                    </Box>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={handleProfile} sx={{ width: 150 }}>
                            <AccountCircle sx={{ marginRight: 1 }} />
                            {t("Profile")}
                        </MenuItem>
                        <MenuItem onClick={handleZ}>
                            <Settings sx={{ marginRight: 1 }} />
                            {t("Settings")}
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ExitToApp sx={{ marginRight: 1 }} />
                            {t("Sign Out")}
                        </MenuItem>
                    </Menu>
                </Stack>
            ) : (
                <>
                    {/* AppBar */}
                    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }} color="inherit" height={60}>
                        <Toolbar sx={{ justifyContent: "space-between" }}>
                            <Stack display="flex" flexDirection="row" justifyContent='flex-end' my={1} borderRadius={2} spacing={0.5}>
                                <IconButton

                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{ mr: 2, display: { lg: "none" } }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <IconButton hidden />
                                <IconButton hidden />

                            </Stack>

                            <Box height={35} >
                                <InternifLogoOnly />
                            </Box>
                            <Stack display="flex" flexDirection="row" justifyContent='flex-end' my={1} borderRadius={2} spacing={0.5}>
                                <LanguagePopover />
                                <NotificationsPopover />
                            </Stack>

                        </Toolbar>
                    </AppBar>

                    {/* Drawer */}
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={drawerOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            marginTop: 80,
                            width: 280,
                            flexShrink: { lg: 0 },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: 250,
                                backgroundColor: '#F9FBFD',
                            },
                        }}
                    >
                        <Stack sx={{ height: 1, }} justifyContent="space-between">
                            <Box>
                                <Box sx={{ mb: 2, mt: 4, ml: 4 }}>
                                    <img width={"80%"} src={InternIFLogo} alt="InternIF Logo" />
                                </Box>
                                <NavSectionVertical
                                    data={[{
                                        subheader: t('Business Management'),
                                        items:
                                            userRole === "internIFFinance" ? [
                                                { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                { title: t('Intern'), path: "/intern", icon: <Groups2Icon /> },
                                                { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                                { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                            ] :
                                                userRole === "internIFMarketing" ? [
                                                    { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                    { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                    { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                    { title: t('Intern'), path: "/intern", icon: <Groups2Icon /> },
                                                    { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                    { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                                    { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                    { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                ] :
                                                    userRole === "internIFTech" ? [
                                                        { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                        { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                        { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                        { title: t('Intern'), path: "/intern", icon: <Groups2Icon /> },
                                                        { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                        { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                                        { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                        { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                    ] :
                                                        userRole === "internIFManager" ? [
                                                            { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                            { title: t('Agent'), path: "/agent", icon: <Groups2Icon /> },
                                                            { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                            { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                            { title: t('Company Agent'), path: "/company-agent", icon: <Groups2Icon /> },
                                                            { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                            { title: t('Intern Partner'), path: "/intern-agent", icon: <Groups2Icon /> },
                                                            { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                            { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                            { title: t('Notepad'), path: "/notepad", icon: <TextSnippetIcon /> },
                                                        ] :
                                                            userRole === "internIFInternManager" ? [
                                                                { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                                { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                                { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                                { title: t('Intern'), path: "/intern", icon: <Groups2Icon /> },
                                                                { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                                { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                                                { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                                { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                            ] :
                                                                userRole === "internIFCustomerRep" ? [
                                                                    { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                                    { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                                    { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                                    { title: t('Intern'), path: "/intern", icon: <Groups2Icon /> },
                                                                    { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                                    { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                                                    { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                                    { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                                ]
                                                                    : userRole === "b2bSalesRep" ? [
                                                                        { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                                        { title: t('Business Partner'), path: "/business-partner", icon: <Groups2Icon /> },
                                                                        { title: t('University'), path: "/university", icon: <SchoolIcon /> },
                                                                        { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                                        { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                                    ] :
                                                                        userRole === "b2bCompanyRep" ? [
                                                                            { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                                            { title: t('Company'), path: "/company", icon: <Groups2Icon /> },
                                                                            { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                                            { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> }
                                                                        ] :
                                                                            userRole === "b2bClient" ? [
                                                                                { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                                                { title: t('Packages'), path: "/packages", icon: <StyleIcon /> },
                                                                                { title: t('Finance'), path: "/finance", icon: <AccountBalanceWalletIcon /> },
                                                                                { title: t('Ticket'), path: "/ticket", icon: <ConfirmationNumberIcon /> },
                                                                            ] : [
                                                                                { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                                                                            ]
                                    }]}
                                />
                            </Box>
                            <Box sx={{ mb: 5, mt: 4, mx: 1, cursor: 'pointer' }}
                                flexDirection="row" display="flex"
                                alignItems="center" bgcolor={'#f5f5f5'} p={1} borderRadius={2}
                                onClick={handleClick}
                            >

                                <Avatar sx={{ width: 40, height: 40 }} src={getUserData?.personalInformation?.profileImageUrl ?
                                    getUserData?.personalInformation?.profileImageUrl : userImage} />
                                <Box sx={{ ml: 2 }}>
                                    <Box sx={{ fontWeight: 600 }}>{getUserData?.personalInformation?.name} {getUserData?.personalInformation?.surname}</Box>
                                    <Box sx={{ fontSize: 12 }}>{
                                        getUserData?.role === 'internIFFinance' ? t("InternIF Finance") :
                                            getUserData?.role === 'internIFMarketing' ? t("InternIF Marketing") :
                                                getUserData?.role === 'internIFTech' ? t("InternIF Tech") :
                                                    getUserData?.role === 'internIFManager' ? t("InternIF Manager") :
                                                        getUserData?.role === 'internIFInternManager' ? t("InternIF Intern Manager") :
                                                            getUserData?.role === 'internIFCustomerRep' ? t("Customer Representative") :
                                                                getUserData?.role === 'b2bSalesRep' ? t("Agent") :
                                                                    getUserData?.role === 'b2bCompanyRep' ? t('Company Agent') :
                                                                        getUserData?.role === 'b2bInternRep' ? t('Intern Partner') :
                                                                            getUserData?.role === 'b2bClient' ? t('Business Partner') :
                                                                                null} </Box>
                                </Box>

                            </Box>
                            <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={handleProfile} sx={{ width: 150 }}>
                                    <AccountCircle sx={{ marginRight: 1 }} />
                                    Profile
                                </MenuItem>
                                <MenuItem onClick={handleZ}>
                                    <Settings sx={{ marginRight: 1 }} />
                                    Settings
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    <ExitToApp sx={{ marginRight: 1 }} />
                                    Sign Out
                                </MenuItem>
                            </Menu>
                        </Stack>
                    </Drawer>
                </>
            )}
        </Box >

        <Grid item xs={12} lg={9}>
            <Stack display="flex" flexDirection="row" justifyContent='flex-end' sx={{ mb: 2 }} p={1} borderRadius={2} spacing={1}>


                {lgUp && <LanguagePopover />}
                {lgUp && <NotificationsPopover />}


            </Stack>
            <PageOpening>
                {children}
            </PageOpening>
        </Grid>


    </Grid>
}

export default BizLayout