import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes } from 'react-router-dom'
import { setUserRole, setAccessToken, setEmail } from 'context/auth/auth';
import { getUserFunc, getInHouseUserFunc } from 'context/common/user';
import { universitiesOverviewFunc } from 'context/salesRep/university';
import { clientsOverviewFunc } from 'context/salesRep/client';
import { companiesOverviewFunc } from 'context/companyRep/company';
import { InternIFFinanceDashboard, InternIFFinanceOnboarding } from 'routes/InternIFFinanceRouter';
import { InternIFMarketingDashboard, InternIFMarketingOnboarding } from 'routes/InternIFMarketingRouter';
import { InternIFTechDashboard, InternIFTechOnboarding } from 'routes/InternIFTechRouter';
import { InternIFManagerDashboard, InternIFManagerOnboarding } from 'routes/InternIFManagerRouter';
import { InternIFInternManagerDashboard, InternIFInternManagerOnboarding } from 'routes/InternIFInternManagerRouter';
import { InternIFCustomerRepDashboard, InternIFCustomerRepOnboarding } from 'routes/InternIFCustomerRepRouter';
import AnonymousRouter from 'routes/AnonymousRouter';
import { useTranslation } from 'react-i18next';
import { packagesOverviewFunc,userBillingInfoFunc } from 'context/common/packages';
import { internIFManagerOverviewFunc } from 'context/internIFManager/common';
import { getUserNotesToSelfFunc } from 'context/common/notes';

const Router = () => {

    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { userRole } = useSelector(state => state.auth);
    const { accessToken } = useSelector(state => state.auth);

    const { onboarded } = useSelector(state => state.user);

    useEffect(() => {
        const lang = localStorage.getItem('lang') || 'en';

        i18n.changeLanguage(lang);

    }, [i18n])

    console.log(onboarded)

    useEffect(() => {
        dispatch(setUserRole(localStorage.getItem('userRole')))
        dispatch(setAccessToken(localStorage.getItem('accessToken')))
        dispatch(setEmail(localStorage.getItem('email')))
    }, [dispatch])

    useEffect(() => {
        accessToken && dispatch(getUserFunc({ accessToken }))
        accessToken && dispatch(getInHouseUserFunc({ accessToken }))
        accessToken && dispatch(getUserNotesToSelfFunc({ accessToken: accessToken }));
        accessToken && userRole === 'b2bSalesRep' && dispatch(universitiesOverviewFunc({ accessToken: accessToken }));
        accessToken && userRole === 'b2bSalesRep' && dispatch(clientsOverviewFunc({ accessToken: accessToken }));
        accessToken && userRole === 'b2bCompanyRep' && dispatch(companiesOverviewFunc({ accessToken: accessToken }));
        accessToken && userRole === "b2bClient" && dispatch(packagesOverviewFunc({ accessToken: accessToken }));
        accessToken && userRole === "internIFManager" && dispatch(internIFManagerOverviewFunc({ accessToken: accessToken }));
        accessToken && userRole === "internIFManager" && dispatch(userBillingInfoFunc({ accessToken: accessToken }));
    }, [accessToken, dispatch, userRole])

    const renderUserTypeRouter = () => {
        switch (userRole) {
            case 'internIFFinance':
                switch (onboarded) {
                    case "completed":
                        return InternIFFinanceDashboard();
                    case "pending":
                        return InternIFFinanceOnboarding();
                    default:
                        return null;
                }
            case 'internIFMarketing':
                switch (onboarded) {
                    case "completed":
                        return InternIFMarketingDashboard();
                    case "pending":
                        return InternIFMarketingOnboarding();
                    default:
                        return null;
                }
            case 'internIFTech':
                switch (onboarded) {
                    case "completed":
                        return InternIFTechDashboard();
                    case "pending":
                        return InternIFTechOnboarding();
                    default:
                        return null;
                }
            case 'internIFManager':
                switch (onboarded) {
                    case "completed":
                        return InternIFManagerDashboard();
                    case "pending":
                        return InternIFManagerOnboarding();
                    default:
                        return null;
                }
            case 'internIFInternManager':
                switch (onboarded) {
                    case "completed":
                        return InternIFInternManagerDashboard();
                    case "pending":
                        return InternIFInternManagerOnboarding();
                    default:
                        return null;
                }
            case 'internIFCustomerRep':
                switch (onboarded) {
                    case "completed":
                        return InternIFCustomerRepDashboard();
                    case "pending":
                        return InternIFCustomerRepOnboarding();
                    default:
                        return InternIFCustomerRepOnboarding();
                }
            default:
                return AnonymousRouter();
        }
    }

    return (
        <BrowserRouter>
            <Routes>
                {renderUserTypeRouter()}
            </Routes>
        </BrowserRouter>
    )

}

export default Router;