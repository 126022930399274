import { useTranslation } from 'react-i18next';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, Typography } from '@mui/material'

const PendingListTable = ({ data, page, pageSize, dataCount, handleChangePage }) => {

    const { t } = useTranslation();


    return <TableContainer component={Paper} >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>{t("Full Name")}</TableCell>
                    <TableCell >{t("Email")}</TableCell>
                    <TableCell>{t("Created At")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.length > 0 ?
                    data.map((item, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{page === 1 ? index + 1 : (page - 1) * pageSize + index + 1}</TableCell>
                            <TableCell component="th" scope="row">{item.name} {item.surname}</TableCell>
                            <TableCell component="th" scope="row">{item.email}</TableCell>
                            <TableCell component="th" scope="row">{new Date(item.createdAt).toLocaleDateString()}
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Remaining {48 - ((new Date() - new Date(item.createdAt)) / (1000 * 60 * 60)).toFixed(0)} hour{(48 - ((new Date() - new Date(item.createdAt)) / (1000 * 60 * 60)).toFixed(0)) > 1 ? "s" : ""}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )) :
                    <TableRow>
                        <TableCell colSpan={6} align="center">No data found</TableCell>
                    </TableRow>
                }
            </TableBody>

        </Table>
        <Box display="flex" justifyContent="center" p={2}>
            <Pagination
                count={Math.ceil(dataCount / pageSize)}
                page={page}
                onChange={handleChangePage}
                color="primary"
            />
        </Box>
    </TableContainer>
}

export default PendingListTable