import { Route } from 'react-router-dom'
import Dashboard from 'pages/internifManager/Dashboard'
import Client from 'pages/internifManager/Client'
import University from 'pages/internifManager/University'
import Finance from 'pages/internifManager/Finance'
import Ticket from 'pages/internifManager/Ticket'
import Profile from 'pages/user/Profile'
import Settings from 'pages/user/Settings'
import Onboarding from 'pages/internifManager/Onboarding'
import Company from 'pages/internifManager/Company'
import CompanyRep from 'pages/internifManager/CompanyRep'
import SalesRep from 'pages/internifManager/SalesRep'
import InternRep from 'pages/internifManager/InternRep'
import Notepad from 'pages/user/Notepad';


export const InternIFManagerDashboard = () => {

    return <>
        <Route path="/" element={<Dashboard />} />
        <Route path="/agent" element={<SalesRep />} />
        <Route path="/business-partner" element={<Client />} />
        <Route path="/university" element={<University />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/company" element={<Company />} />
        <Route path="/company-agent" element={<CompanyRep />} />
        <Route path="/intern-agent" element={<InternRep />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/notepad" element={<Notepad />} />
        <Route path="*" element={<Dashboard />} />
    </>
}


export const InternIFManagerOnboarding= () => {
    return <>
        <Route path="/" element={<Onboarding />} />
    </>
}