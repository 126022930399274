import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BizLayout from "layouts/biz/BizLayout"
import {
    Box, Button, Grid, Typography, Paper, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, CircularProgress,
} from '@mui/material'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSnackbar } from 'notistack';
import { internIFManager_registerFunc, pendingUsersFunc } from "context/auth/auth";
import { MedalBox, PendingListTable, InternRepListTable, InternlistShortTable } from "components";
import { useTranslation } from 'react-i18next';
import FileCopy from '@mui/icons-material/FileCopy';
import { internRepListFunc, internsOfInternRepFunc } from "context/internIFManager/internRep";
import { internIFManagerOverviewFunc } from "context/internIFManager/common";
import { createNoteToUserFunc, getUserNotesToUserFunc, updateNoteFunc } from 'context/common/notes';

const info = [""
]

const validationSchema = Yup.object({
    name: Yup.string().transform((originalValue, originalObject) => {
        if (!originalValue) {
            return undefined;
        }
        if (originalValue.length < 2) {
            throw new Yup.ValidationError('Name must be at least 2 characters long', originalValue, 'name');
        }
        if (!/^[a-zA-ZğüşıöçĞÜŞİÖÇ.,\s]*$/.test(originalValue)) {
            throw new Yup.ValidationError('Name can\'t contain special characters', originalValue, 'name');
        }
        return originalValue;
    }).required('Name is required'),
    surname: Yup.string().transform((originalValue, originalObject) => {
        if (!originalValue) {
            return undefined;
        }
        if (originalValue.length < 2) {
            throw new Yup.ValidationError('Surname must be at least 2 characters long', originalValue, 'surname');
        }
        if (!/^[a-zA-ZğüşıöçĞÜŞİÖÇ.,\s]*$/.test(originalValue)) {
            throw new Yup.ValidationError('Surname can\'t contain special characters', originalValue, 'surname');
        }
        return originalValue;
    }).required('Surname is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const pageSize = 10;

const InternRep = () => {

    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken, internIFManager_registerLoading, internIFManager_registerData } = useSelector((state) => state.auth);
    const { internRepListData, internRepListCount, internsOfInternRepData, internsOfInternRepCount } = useSelector((state) => state.internRepForManager);
    const { internRepOverviewData } = useSelector((state) => state.commonForManager);
    const { pendingUsersData, pendingUsersCount } = useSelector((state) => state.auth);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [pagePending, setPagePending] = useState(1);
    const { getUserNotesToUserData } = useSelector((state) => state.notes);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openList, setOpenList] = useState(false);


    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        formikNote.resetForm();
        formik.resetForm();
    };

    const handleChangePending = (event, value) => {
        setPagePending(value);
    };


    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: '',
            role: 'b2bInternRep',
            accessToken: accessToken
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(internIFManager_registerFunc(values)).then((res) => {
                if (res?.payload.isSuccess) {
                    enqueueSnackbar('Intern Partner added successfully', { variant: 'success' });

                    process.env.REACT_APP_TEST === "true" && enqueueSnackbar(<div>
                        {"Registration link: "}
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => {
                                const textToCopy = `http://biz.internif.com/create-new-password/?token=${res?.payload.data.token}&reset=${res?.payload.data.resetPasswordToken}&code=${res?.payload.data.code}`
                                const textArea = document.createElement('textarea');
                                textArea.value = textToCopy;
                                document.body.appendChild(textArea);
                                textArea.select();
                                document.execCommand('copy');
                                document.body.removeChild(textArea);
                            }}
                        >
                            Copy Link
                            <FileCopy />
                        </Button>
                    </div>, { variant: 'warning', autoHideDuration: 6000 });

                    handleClose();
                } else if (res?.payload?.response?.data?.errors?.[0]?.msg === 'Email already in use') {
                    enqueueSnackbar("Email already in use!", { variant: 'error' });
                } else {
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                }
            }).catch((err) => {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
            });
        },
    });

    const handleClickOpenEdit = (item) => {
        setOpenEdit(true);
        setSelectedItem(item);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setSelectedItem(null);
        formikNote.resetForm();
        formik.resetForm();
    };


    const handleClickOpenList = (item) => {
        console.log(item);
        setSelectedItem(item);
        setOpenList(true);

        dispatch(internsOfInternRepFunc({ accessToken: accessToken, internRepId: item?._id }));
    };

    const handleCloseList = () => {
        setOpenList(false);
        setSelectedItem(null);
    };

    console.log(selectedItem, "selectedItem")


    const formikNote = useFormik({
        enableReinitialize: true,
        initialValues: {
            note: getUserNotesToUserData?.content ?? '',
        },
        onSubmit: (values) => {
            getUserNotesToUserData ?
                dispatch(updateNoteFunc({
                    content: values.note,
                    accessToken: accessToken,
                    noteId: getUserNotesToUserData._id
                })).then((res) => {
                    dispatch(getUserNotesToUserFunc({ accessToken: accessToken, targetId: selectedItem?._id }));
                    enqueueSnackbar('Note updated successfully', { variant: 'success' });
                    handleCloseEdit();
                }).catch((err) => {
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                })
                :
                dispatch(createNoteToUserFunc({ accessToken: accessToken, title: selectedItem?._id, targetId: selectedItem?._id, content: values.note })).then((res) => {

                    if (res?.payload?.user_createNoteToUser?._id) {
                        enqueueSnackbar('Note added successfully', { variant: 'success' });
                        handleCloseEdit();
                    } else {
                        enqueueSnackbar('Something went wrong', { variant: 'error' });
                    }
                }).catch((err) => {
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                });
        },
    });

    useEffect(() => {
        accessToken && selectedItem && dispatch(getUserNotesToUserFunc({ accessToken: accessToken, targetId: selectedItem?.companyAdmin?._id }));
    }, [selectedItem, accessToken, dispatch])

    useEffect(() => {
        dispatch(internRepListFunc({ accessToken: accessToken, page: page, pageSize: pageSize }));
        dispatch(internIFManagerOverviewFunc({ accessToken: accessToken }));
    }, [dispatch, accessToken, page, internIFManager_registerData]);

    useEffect(() => {
        dispatch(pendingUsersFunc({ accessToken: accessToken, status: "pending", role: 'b2bInternRep', page: pagePending, pageSize: pageSize }));
    }, [dispatch, accessToken, pagePending, internIFManager_registerData]);


    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Intern Partner")}</Typography>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t('Bronze')}
                        bgColor="#FFEFE2"
                        count={internRepOverviewData?.bronzeCount || 0}
                        uppercase
                        label={t("Bronze")}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t('Silver')}
                        bgColor="#F5F5F5"
                        count={internRepOverviewData?.silverCount || 0}
                        uppercase
                        label={t("Silver")}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t('Gold')}
                        bgColor="#fff1ba"
                        count={internRepOverviewData?.goldCount || 0}
                        uppercase
                        label={t("Gold")}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MedalBox
                        medalType={t('VIP')}
                        bgColor="#383e42"
                        count={internRepOverviewData?.vipCount || 0}
                        uppercase
                        label={t("VIP")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={3} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h5">{t("Intern Partner List")}</Typography>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent={"end"}>
                    <Button onClick={handleClickOpen} variant="contained" color="primary" size="large">
                        <AddCircleOutlineIcon color="white" fontSize="medium" style={{ marginRight: 5 }} />
                        {t("Add Intern Partner")}
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={0} mt={3} sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
            }} component={Paper} >
                <InternRepListTable
                    data={internRepListData}
                    dataCount={internRepListCount}
                    page={page}
                    pageSize={pageSize}
                    handleChangePage={handleChange}
                    handleClickOpenList={handleClickOpenList}
                    handleClickOpenEdit={handleClickOpenEdit}
                />
            </Grid>

            <Grid container spacing={2} mt={3} alignItems="center">
                <Grid item xs={6} >
                    <Typography variant="h5">{t("Pending Intern Partner List")}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={0} mt={3} sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.1)",
            }} component={Paper} >
                <PendingListTable
                    data={pendingUsersData}
                    page={pagePending}
                    pageSize={pageSize}
                    dataCount={pendingUsersCount}
                    handleChangePage={handleChangePending}
                />
            </Grid>

            <Box height={40} />
        </Box>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle id="form-dialog-title">{t("Add Intern Partner")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("To add a Intern partner, please enter the intern partner information here.")}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label={t("Name")}
                        inputProps={{ maxLength: 35 }}
                        type="text"
                        fullWidth
                        value={formik.values.name}
                        onChange={(e) => {
                            const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                            formik.setFieldValue('name', capitalizedValue);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />

                    <TextField
                        margin="dense"
                        id="surname"
                        name="surname"
                        label={t("Surname")}
                        inputProps={{ maxLength: 35 }}
                        type="text"
                        fullWidth
                        value={formik.values.surname}
                        onChange={(e) => {
                            const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                            formik.setFieldValue('surname', capitalizedValue);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched.surname && Boolean(formik.errors.surname)}
                        helperText={formik.touched.surname && formik.errors.surname}
                    />

                    <TextField
                        margin="dense"
                        id="email"
                        name="email"
                        label={t("Email")}
                        inputProps={{ maxLength: 35 }}
                        type="email"
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        {t("Cancel")}
                    </Button>
                    <Button type="submit" variant="contained" color="primary"
                        disabled={internIFManager_registerLoading}
                    >
                        {internIFManager_registerLoading ? <CircularProgress size={20} /> : t("Add")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

        <Dialog open={openEdit}
            onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
            <form onSubmit={formikNote.handleSubmit}>
                <DialogTitle id="form-dialog-title">{t("Notes")}</DialogTitle>
                <DialogContent>
                    <DialogContentText mb={1} fontWeight="bold">
                        {selectedItem?.displayName}
                    </DialogContentText>

                    <TextField
                        margin="dense"
                        id="note"
                        name="note"
                        label={t("Note")}
                        placeholder="You can write your notes here."
                        type="text"
                        sx={{ minWidth: { xs: '100%', md: 400 } }}
                        multiline
                        rows={4}
                        value={formikNote.values.note}
                        onChange={formikNote.handleChange}
                        onBlur={formikNote.handleBlur}
                        error={formikNote.touched.note && Boolean(formikNote.errors.note)}
                        helperText={formikNote.touched.note && formikNote.errors.note}
                    />



                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary"
                        disabled={null}
                    >
                        {false ? <CircularProgress size={20} /> : "Save"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

        <Dialog open={openList}
            onClose={handleCloseList} aria-labelledby="form-dialog-title">
            <form onSubmit={formikNote.handleSubmit}>
                <DialogTitle id="form-dialog-title">{t("Intern List")}</DialogTitle>
                <DialogContent>
                    <InternlistShortTable
                        data={internsOfInternRepData}
                        dataCount={internsOfInternRepCount}
                        page={page}
                        pageSize={pageSize}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseList} color="primary" variant="outlined">
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

    </BizLayout>
}

export default InternRep