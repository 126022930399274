import { Route } from 'react-router-dom'
import Dashboard from 'pages/internifCustomerRep/Dashboard'
import Client from 'pages/internifCustomerRep/Client'
import University from 'pages/internifCustomerRep/University'
import Finance from 'pages/internifCustomerRep/Finance'
import Ticket from 'pages/internifCustomerRep/Ticket'
import Profile from 'pages/user/Profile'
import Settings from 'pages/user/Settings'
import Onboarding from 'pages/internifCustomerRep/Onboarding'
import Company from 'pages/internifCustomerRep/Company'
import Intern from 'pages/internifCustomerRep/Intern'
import Packages from 'pages/internifCustomerRep/Packages'


export const InternIFCustomerRepDashboard = () => {

    return <>
        <Route path="/" element={<Dashboard />} />
        <Route path="/client" element={<Client />} />
        <Route path="/university" element={<University />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/company" element={<Company />} />
        <Route path="/intern" element={<Intern />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="*" element={<Dashboard />} />
    </>
}


export const InternIFCustomerRepOnboarding= () => {
    return <>
        <Route path="/" element={<Onboarding />} />
    </>
}