
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Card, Typography, Stack, TextField, FormControl, InputLabel, Select, MenuItem, Button, FormHelperText, CircularProgress } from '@mui/material';
import { MobileDatePicker as MDPicker } from '@mui/x-date-pickers/MobileDatePicker';
import BizLayout from 'layouts/biz/BizLayout';
import SaveIcon from '@mui/icons-material/Save';
import { languagesFunc, languageLevelsFunc, countriesFunc, citiesFunc } from 'context/common/parameters';
import { updateUserFunc, getUserFunc, getProfilePicturePresignedUrlFunc, getInHouseUserFunc } from 'context/common/user';
import {
    self_updateUserInvoiceInfoFunc, self_updateOrganizationInvoiceInfoFunc, userBillingInfoData, userBillingInfoFunc,
    self_updateUserInvoiceInfoFunc2, self_updateOrganizationInvoiceInfoFunc2
} from 'context/common/packages';
import './style.css';
import Compressor from 'compressorjs';
import uploadImage from 'hooks/uploadImage';
import userImage from 'assets/images/common/user.png';
import { useResponsive } from "hooks/use-responsive"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MuiTelInput } from 'mui-tel-input'

const info = [
    "Bitti"
]

const Profile = () => {

    const { accessToken } = useSelector((state) => state.auth);
    const { languagesData, languageLevelsData, countriesData, citiesData } = useSelector((state) => state.parameters);
    const { getUserData } = useSelector((state) => state.user);
    const { getInHouseUserData } = useSelector((state) => state.user);
    const { userBillingInfoData } = useSelector((state) => state.packages);
    const dispatch = useDispatch();
    const smUp = useResponsive('up', 'sm');
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation()
    // let type = getInHouseUserData?.inHouseOrganization?.inHouseOrganizationType;
    let type = getInHouseUserData?.inHouseOrganization?.inHouseOrganizationType
    const [compressedImage, setCompressedImage] = useState(null);
    const [compressedImageUrl, setCompressedImageUrl] = useState(null);
    const [compressedCompanyLogo, setCompressedCompanyLogo] = useState(null);
    const [compressedCompanyLogoUrl, setCompressedCompanyLogoUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: getUserData?.personalInformation?.name,
            surname: getUserData?.personalInformation?.surname,
            birthDate: new Date(getUserData?.personalInformation?.birthDate),
            email: getUserData?.contact?.email,
            gender: getUserData?.personalInformation?.gender,
            address: getUserData?.personalInformation?.address,
            nationality: getUserData?.personalInformation?.nationality?._id,
            type: getInHouseUserData?.inHouseOrganization?.inHouseOrganizationType,
            organizationType: getInHouseUserData?.inHouseOrganization?.company?.inHouseCompanyType,
            organizationName: getInHouseUserData?.inHouseOrganization?.company?.name,
            phone: getUserData?.contact?.phoneNumber,
            language: getUserData?.personalInformation?.language?._id,
            languageLevel: getUserData?.personalInformation?.languageLevel?._id,
            country: getUserData?.personalInformation?.country?._id,
            city: getUserData?.personalInformation?.city?._id,
            universityName: getInHouseUserData?.inHouseOrganization?.university?.name,
            universityClubName: getInHouseUserData?.inHouseOrganization?.university?.logoImageUrl,
            profileImageUrl: getUserData?.personalInformation?.profileImageUrl,
            companyLogoImageUrl: type === "company" ? getInHouseUserData?.inHouseOrganization?.company?.logoImageUrl :
                type === "university" ? getInHouseUserData?.inHouseOrganization?.university?.logoImageUrl :
                    type === "universityClub" ? getInHouseUserData?.inHouseOrganization?.university?.logoImageUrl : '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('First Name is required').min(2, 'First Name must be at least 2 characters'),
            surname: Yup.string().required('Last Name is required').min(2, 'Last Name must be at least 2 characters'),
            birthDate: Yup.date().required('Birth Date is required'),
            gender: Yup.string().required('Gender is required'),
            address: Yup.string().nullable(),
            nationality: Yup.string().required("Nationality is required"),
            type: Yup.string().required('User Type is required'),
            phone: Yup.string().nullable().min(6, 'Phone Number must be at least 6 characters').max(20, 'Phone Number must be at most 20 characters'),
            organizationType: type === "company" && Yup.string().required('Organization Type is required'),
            organizationName: type === "company" && Yup.string().required('Organization Name is required').min(3, 'Organization Name must be at least 3 characters'),
            language: Yup.string().required('Language is required'),
            languageLevel: Yup.string().required('Language Level is required'),
            country: Yup.string().required('Country is required'),
            city: Yup.string().required('City is required'),
            universityName: type === "university" && Yup.string().required('University Name is required').min(3, 'University Name must be at least 3 characters'),
            universityClubName: type === "universityClub" && Yup.string().required('University Club Name is required').min(3, 'University Club Name must be at least 3 characters'),
            profileImageUrl: Yup.string().nullable(),
            companyLogoImageUrl: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            console.log(values)
            handleSubmitForm();
        }
    });

    console.log(values.type, 'type')
    console.log(values.organizationType, 'organizationType')



    const handleSubmitForm = () => {

        setLoading(true);

        if (type === 'individual') {
            if (compressedImage) {
                dispatch(getProfilePicturePresignedUrlFunc({ accessToken, type: 'jpegImage' })).then((res) => {
                    const presignedUploadUrl = res.payload?.userPresignedUrls?.profileImage?.presignedUploadUrl;
                    const url = res.payload?.userPresignedUrls?.profileImage?.url;

                    uploadImage({ data: compressedImage, presignedUploadUrl: presignedUploadUrl });

                    dispatch(updateUserFunc({
                        accessToken, name: values.name, surname: values.surname, birthDate: values.birthDate,
                        gender: values.gender, nationality: values.nationality, language: values.language, languageLevel: values.languageLevel,
                        countryId: values.country, type: values.type, phoneNumber: values.phone,
                        cityId: values.city, address: values.address,
                        profileImageUrl: url,
                    })).then(() => {
                        enqueueSnackbar('Profile updated successfully', { variant: 'success' });
                        dispatch(self_updateUserInvoiceInfoFunc2({
                            accessToken, type: userBillingInfoData?.invoiceInfo?.type,
                            name: values.name, surname: values.surname
                        }));
                        setLoading(false);
                        dispatch(getUserFunc({ accessToken }));
                        dispatch(getInHouseUserFunc({ accessToken }));
                    }).catch((err) => {
                        enqueueSnackbar('Profile update failed', { variant: 'error' });
                        console.log(err)
                    })
                })
            } else {
                dispatch(updateUserFunc({
                    accessToken, name: values.name, surname: values.surname, birthDate: values.birthDate,
                    gender: values.gender, nationality: values.nationality, language: values.language, languageLevel: values.languageLevel,
                    countryId: values.country, type: values.type, phoneNumber: values.phone,
                    cityId: values.city, address: values.address,
                    profileImageUrl: values.profileImageUrl
                })).then(() => {
                    enqueueSnackbar('Profile updated successfully', { variant: 'success' });
                    dispatch(self_updateUserInvoiceInfoFunc2({
                        accessToken, type: userBillingInfoData?.invoiceInfo?.type,
                        name: values.name, surname: values.surname
                    }));

                    setLoading(false);
                    dispatch(getUserFunc({ accessToken }));
                    dispatch(getInHouseUserFunc({ accessToken }));
                }).catch((err) => {
                    enqueueSnackbar('Profile update failed', { variant: 'error' });
                    console.log(err)
                })
            }
        }
        if (type === 'company') {
            (!compressedCompanyLogo && !compressedImage) ?
                dispatch(updateUserFunc({
                    accessToken, name: values.name, surname: values.surname, birthDate: values.birthDate,
                    gender: values.gender, nationality: values.nationality, language: values.language, languageLevel: values.languageLevel,
                    countryId: values.country, type: values.type, phoneNumber: values.phone,
                    cityId: values.city, address: values.address,
                    companyType: values.organizationType,
                    companyName: values.organizationName,
                    companyLogoImageUrl: values.companyLogoImageUrl,
                    profileImageUrl: values.profileImageUrl
                })).then(() => {
                    enqueueSnackbar('Profile updated successfully', { variant: 'success' });
                    dispatch(self_updateUserInvoiceInfoFunc2({
                        accessToken, type: userBillingInfoData?.invoiceInfo?.type,
                        name: values.name, surname: values.surname
                    }));
                    setLoading(false);

                    dispatch(getUserFunc({ accessToken }));
                    dispatch(getInHouseUserFunc({ accessToken }));
                }).catch((err) => {
                    enqueueSnackbar('Profile update failed', { variant: 'error' });
                    console.log(err)
                })
                : (compressedCompanyLogo && !compressedImage) ?
                    dispatch(getProfilePicturePresignedUrlFunc({ accessToken, type: 'jpegImage' })).then((res) => {
                        const presignedUploadUrl = res.payload?.userPresignedUrls?.backgroundImage?.presignedUploadUrl;
                        const url = res.payload?.userPresignedUrls?.backgroundImage?.url;

                        uploadImage({ data: compressedCompanyLogo, presignedUploadUrl: presignedUploadUrl });

                        dispatch(updateUserFunc({
                            accessToken, name: values.name, surname: values.surname, birthDate: values.birthDate,
                            gender: values.gender, nationality: values.nationality, language: values.language, languageLevel: values.languageLevel,
                            countryId: values.country, type: values.type, phoneNumber: values.phone,
                            cityId: values.city, address: values.address,
                            companyType: values.organizationType,
                            companyName: values.organizationName,
                            companyLogoImageUrl: url,
                            profileImageUrl: values.profileImageUrl
                        })).then(() => {
                            enqueueSnackbar('Profile updated successfully', { variant: 'success' });
                            dispatch(self_updateUserInvoiceInfoFunc2({
                                accessToken, type: userBillingInfoData?.invoiceInfo?.type,
                                name: values.name, surname: values.surname
                            }));
                            setLoading(false);

                            dispatch(getUserFunc({ accessToken }));
                            dispatch(getInHouseUserFunc({ accessToken }));
                            setCompressedCompanyLogo(null);
                        }).catch((err) => {
                            enqueueSnackbar('Profile update failed', { variant: 'error' });
                            console.log(err)
                        })
                    })
                    : (!compressedCompanyLogo && compressedImage) ?
                        dispatch(getProfilePicturePresignedUrlFunc({ accessToken, type: 'jpegImage' })).then((res) => {
                            const presignedUploadUrl = res.payload?.userPresignedUrls?.profileImage?.presignedUploadUrl;
                            const url = res.payload?.userPresignedUrls?.profileImage?.url;

                            uploadImage({ data: compressedImage, presignedUploadUrl: presignedUploadUrl });

                            dispatch(updateUserFunc({
                                accessToken, name: values.name, surname: values.surname, birthDate: values.birthDate,
                                gender: values.gender, nationality: values.nationality, language: values.language, languageLevel: values.languageLevel,
                                countryId: values.country, type: values.type, phoneNumber: values.phone,
                                cityId: values.city, address: values.address,
                                companyType: values.organizationType,
                                companyName: values.organizationName,
                                profileImageUrl: url,
                                companyLogoImageUrl: values.companyLogoImageUrl
                            })).then(() => {
                                enqueueSnackbar('Profile updated successfully', { variant: 'success' });
                                dispatch(self_updateUserInvoiceInfoFunc2({
                                    accessToken, type: userBillingInfoData?.invoiceInfo?.type,
                                    values: { name: values.name, surname: values.surname }
                                }));
                                setLoading(false);

                                dispatch(getUserFunc({ accessToken }));
                                dispatch(getInHouseUserFunc({ accessToken }));
                                setCompressedCompanyLogo(null);
                            }).catch((err) => {
                                enqueueSnackbar('Profile update failed', { variant: 'error' });
                                console.log(err)
                            })
                        })
                        :
                        dispatch(getProfilePicturePresignedUrlFunc({ accessToken, type: 'jpegImage' })).then((res) => {
                            const presignedUploadUrl = res.payload?.userPresignedUrls?.profileImage?.presignedUploadUrl;
                            const url = res.payload?.userPresignedUrls?.profileImage?.url;
                            const presignedUploadUrl2 = res.payload?.userPresignedUrls?.backgroundImage?.presignedUploadUrl;
                            const url2 = res.payload?.userPresignedUrls?.backgroundImage?.url;

                            uploadImage({ data: compressedImage, presignedUploadUrl: presignedUploadUrl });
                            uploadImage({ data: compressedCompanyLogo, presignedUploadUrl: presignedUploadUrl2 });

                            dispatch(updateUserFunc({
                                accessToken, name: values.name, surname: values.surname, birthDate: values.birthDate,
                                gender: values.gender, nationality: values.nationality, language: values.language, languageLevel: values.languageLevel,
                                countryId: values.country, type: values.type, phoneNumber: values.phone,
                                cityId: values.city, address: values.address,
                                companyType: values.organizationType,
                                companyName: values.organizationName,
                                profileImageUrl: url,
                                companyLogoImageUrl: url2
                            })).then(() => {
                                enqueueSnackbar('Profile updated successfully', { variant: 'success' });
                                dispatch(self_updateUserInvoiceInfoFunc2({
                                    accessToken, type: userBillingInfoData?.invoiceInfo?.type,
                                    name: values.name, surname: values.surname
                                }));
                                setLoading(false);

                                dispatch(getUserFunc({ accessToken }));
                                dispatch(getInHouseUserFunc({ accessToken }));
                                setCompressedCompanyLogo(null);
                            }).catch((err) => {
                                enqueueSnackbar('Profile update failed', { variant: 'error' });
                                console.log(err)
                            })
                        })
        }

    };

    const handleChangeProfileImageButton = () => {
        const fileInput = document.getElementById('file-input');
        fileInput.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        new Compressor(file, {
            quality: 0.4,
            success(result) {
                const formData = new FormData();
                formData.append('file', result, result.name);
                setCompressedImage(result);
                setCompressedImageUrl(URL.createObjectURL(result));
            },
            error(err) {
                console.log(err.message);
            },
        });
    };

    const handleChangeCompanyLogoButton = () => {
        // Dosya seçme işlemi için gizli input elemanını tetikle
        const fileInput = document.getElementById('file-input2');
        fileInput.click();
    };

    const handleCompanyLogoChange = (e) => {
        const file = e.target.files[0];

        new Compressor(file, {
            quality: 0.4,
            success(result) {
                const formData = new FormData();
                formData.append('file', result, result.name);
                setCompressedCompanyLogo(result);
                setCompressedCompanyLogoUrl(URL.createObjectURL(result));
            },
            error(err) {
                console.log(err.message);
            },
        });
    };





    useEffect(() => {
        dispatch(languagesFunc({ accessToken }));
        dispatch(languageLevelsFunc({ accessToken }));
        dispatch(countriesFunc({ accessToken }));
        dispatch(userBillingInfoFunc({ accessToken }));
    }, [dispatch, accessToken]);

    useEffect(() => {
        values.country && dispatch(citiesFunc({ accessToken, countryId: values.country }));
    }, [dispatch, accessToken, values.country]);



    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Stack flexDirection={{ xs: "column", lg: "row" }} justifyContent="space-between">
                <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }}>{t("Profile")}</Typography>
                {smUp && <Button size='large' variant="contained" color="primary" sx={{ width: { xs: '160px', lg: '160px' }, alignSelf: 'end' }} onClick={handleSubmit}
                    disabled={loading}
                >
                    <SaveIcon sx={{ mr: 1, float: "left" }} />{loading ? <CircularProgress size={"1rem"} sx={{ ml: 3 }} /> : 'Save Changes'}</Button>}
            </Stack>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={12} sm={3}  >
                    <Card>
                        <Stack spacing={2} p={2} flexDirection="column" justifyContent="space-evenly" alignItems={"center"}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    src={compressedImageUrl ? compressedImageUrl : getUserData?.personalInformation?.profileImageUrl ?
                                        getUserData?.personalInformation?.profileImageUrl : userImage}
                                    alt="profile" style={{
                                        borderRadius: "50%", width: 100, height: 100, borderWidth: 1, objectFit: 'cover',
                                        borderStyle: 'dashed', borderColor: "#bbb", padding: 5
                                    }} />
                            </Box>


                            <Button variant="outlined" sx={{ width: { xs: '100%', lg: '70%' } }} onClick={handleChangeProfileImageButton}>{t("Change Profile Photo")}</Button>
                            <input
                                id="file-input"
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </Stack>
                        <Box mt={5} p={2}>
                            <FormControl sx={{ width: { xs: '100%', lg: '100%', mt: 50 } }}>
                                <InputLabel id="demo-simple-select-label">{t("User Type")}</InputLabel>
                                <Select
                                    disabled
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.type}
                                    label={t("User Type")}
                                    onChange={handleChange}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                >
                                    <MenuItem value={"individual"}> {t("Individual")} </MenuItem>
                                    <MenuItem value={"company"}> {t("Organization")} </MenuItem>
                                    {/* <MenuItem value={"university"}> {t("University")} </MenuItem>
                                    <MenuItem value={"universityClub"}> {t("University Club")} </MenuItem> */}
                                </Select>
                            </FormControl>
                            <TextField
                                inputProps={{ maxLength: 35 }}
                                disabled name="email" label={t("Email")} variant="outlined" fullWidth
                                sx={{ mt: 2 }}
                                onChange={handleChange}
                                value={values.email} />
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={9}>
                    {(type === 'company' || type === "universityClub") && <Card sx={{ mb: 2 }}>
                        <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                            <FormControl sx={{ width: { xs: '100%' } }} error={touched.organizationType && errors.organizationType}>
                                <InputLabel id="demo-simple-select-label">{t("Organization Type")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    name='organizationType'
                                    id="demo-simple-select"
                                    value={values.organizationType}
                                    onBlur={handleBlur}
                                    label="Organization Type"
                                    onChange={handleChange}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                >
                                    <MenuItem value={"publicCompany"}> {t("Public Company")} </MenuItem>
                                    <MenuItem value={"selfEmployed"}> {t("Self-employed")} </MenuItem>
                                    <MenuItem value={"governmentAgency"}> {t("Government Agency")} </MenuItem>
                                    <MenuItem value={"nonprofit"}> {t("Nonprofit")}</MenuItem>
                                    <MenuItem value={"soleProprietorship "}> {t("Sole Proprietorship")} </MenuItem>
                                    <MenuItem value={"limitedLiabilityCompany"}> {t("Limited Liability Company")}</MenuItem>
                                    <MenuItem value={"corporation"}> {t("Corporation")}</MenuItem>
                                    <MenuItem value={"partnership"}> {t("Partnership")}</MenuItem>
                                </Select>
                                {touched.organizationType && errors.organizationType && <FormHelperText style={{ color: '#961650' }}>{errors.organizationType}</FormHelperText>}

                            </FormControl>
                            {type === 'universityClub' && <TextField
                                error={touched.universityClubName && errors.universityClubName}
                                helperText={touched.universityClubName && errors.universityClubName}
                                inputProps={{ maxLength: 35 }}
                                onBlur={handleBlur}
                                label={t("University Club Name")}
                                name='universityClubName'
                                variant="outlined"
                                sx={{ width: { xs: '100%', lg: '50%' } }}
                                onChange={(e => {
                                    const enteredValue = e.target.value;
                                    const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                    setFieldValue("universityClubName", capitalizedValue)
                                })}
                                value={values.universityName} />}
                        </Stack>
                        {type === 'company' && <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }} alignItems="center">
                            <TextField
                                inputProps={{ maxLength: 35 }}
                                error={touched.organizationName && errors.organizationName}
                                helperText={touched.organizationName && errors.organizationName}
                                onBlur={handleBlur}
                                label={t("Organization Name")}
                                variant="outlined" fullWidth
                                name='organizationName'
                                onChange={(e => {
                                    const enteredValue = e.target.value;
                                    const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                    setFieldValue("organizationName", capitalizedValue)
                                })}
                                value={values.organizationName}
                            />
                            <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: '#F5F5F5', padding: 1, borderRadius: "12px", cursor: "pointer" }} onClick={handleChangeCompanyLogoButton}>
                                <img
                                    src={compressedCompanyLogoUrl ? compressedCompanyLogoUrl : values.companyLogoImageUrl ? values.companyLogoImageUrl : userImage}
                                    alt="profile" style={{ objectFit: 'cover', borderRadius: "20%", width: 50, height: 50, borderWidth: 1, borderStyle: 'dashed', borderColor: "#bbb", padding: 2 }} />
                                <Typography display="flex" sx={{ ml: 2 }}>{t("Organization Logo")} <CloudUploadIcon sx={{ ml: 1, color: '#555' }} />   </Typography>
                            </Box>
                            <input
                                id="file-input2"
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleCompanyLogoChange}
                            />
                        </Stack>}
                        {type === 'university' && <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }} alignItems="center">
                            <TextField
                                inputProps={{ maxLength: 35 }}
                                name='universityName'
                                error={touched.universityName && errors.universityName}
                                helperText={touched.universityName && errors.universityName}
                                onBlur={handleBlur}
                                label="University Name" variant="outlined" fullWidth
                                onChange={(e => {
                                    const enteredValue = e.target.value;
                                    const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                    setFieldValue("universityName", capitalizedValue)
                                })}
                                value={values.universityName} />
                            <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: '#F5F5F5', padding: 1, borderRadius: "12px" }}>
                                <img src="https://picsum.photos/100" alt="profile" style={{ borderRadius: "20%", width: 50, height: 50, borderWidth: 1, borderStyle: 'dashed', borderColor: "#bbb", padding: 2 }} />
                                <Typography display="flex" sx={{ ml: 2 }}>{t("University Logo")} <CloudUploadIcon sx={{ ml: 1, color: '#555' }} />  </Typography>
                            </Box>
                        </Stack>}
                        {type === 'universityClub' && <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }} alignItems="center">
                            <TextField
                                inputProps={{ maxLength: 35 }}
                                name='universityClubName'
                                error={touched.universityClubName && errors.universityClubName}
                                helperText={touched.universityClubName && errors.universityClubName}
                                onBlur={handleBlur}
                                label="University Club Name" variant="outlined" fullWidth
                                onChange={(e => {
                                    const enteredValue = e.target.value;
                                    const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                    setFieldValue("universityClubName", capitalizedValue)
                                })}
                                value={values.universityClubName} />
                            <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: '#F5F5F5', padding: 1, borderRadius: "12px" }}>
                                <img src="https://picsum.photos/100" alt="profile" style={{ borderRadius: "20%", width: 50, height: 50, borderWidth: 1, borderStyle: 'dashed', borderColor: "#bbb", padding: 2 }} />
                                <Typography display="flex" sx={{ ml: 2 }}>{t("Logo")} <CloudUploadIcon sx={{ ml: 1, color: '#555' }} />  </Typography>
                            </Box>
                        </Stack>}
                    </Card>}
                    <Card>
                        <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                            <TextField
                                inputProps={{ maxLength: 35 }}
                                name="name"
                                label={t("Name")}
                                error={touched.name && errors.name}
                                helperText={touched.name && errors.name}
                                onBlur={handleBlur}
                                variant="outlined"
                                fullWidth
                                onChange={(e => {
                                    const enteredValue = e.target.value;
                                    const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                    setFieldValue("name", capitalizedValue)
                                })}
                                value={values.name} />
                            <TextField
                                inputProps={{ maxLength: 35 }}
                                name="surname" label={t("Surname")}
                                error={touched.surname && errors.surname}
                                helperText={touched.surname && errors.surname}
                                onBlur={handleBlur}
                                variant="outlined"
                                fullWidth
                                onChange={(e => {
                                    const enteredValue = e.target.value;
                                    const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                    setFieldValue("surname", capitalizedValue)
                                })}
                                value={values.surname} />
                        </Stack>
                        <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                            <MDPicker
                                sx={{ width: '100%' }}
                                name="birthDate"
                                label="Birthday*"
                                value={values.birthDate}
                                openTo="year"
                                format="dd MMM yyyy"
                                views={['year', 'month', 'day']}
                                onChange={(e) => setFieldValue('birthDate', e)}
                                className="customInput"
                                maxDate={new Date() - 1000 * 60 * 60 * 24 * 365 * 16}
                                minDate={new Date() - 1000 * 60 * 60 * 24 * 365 * 100}
                                onBlur={handleBlur}
                                slotProps={{
                                    textField: {
                                        variant: "outlined",
                                        error: Boolean(errors.birthDate) && touched.birthDate,
                                        helperText: Boolean(errors.birthDate) && touched.birthDate
                                    }
                                }}
                            />
                            <MuiTelInput
                                label={t("Phone")}
                                name="phone"
                                value={values.phone}
                                onChange={(e) => setFieldValue('phone', e)}
                                defaultCountry={'tr'}
                                variant="outlined"
                                inputProps={{ maxLength: 20 }}
                                fullWidth
                                error={touched.phone && errors.phone}
                                helperText={touched.phone && errors.phone}
                                onBlur={handleBlur}
                            />
                        </Stack>
                        <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>

                            <FormControl fullWidth error={touched.gender && errors.gender}>
                                <InputLabel id="demo-simple-select-label">{t("Gender")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='gender'
                                    onBlur={handleBlur}
                                    value={values.gender}
                                    label={t("Gender")}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"male"}>Male</MenuItem>
                                    <MenuItem value={"female"}>Female</MenuItem>
                                    <MenuItem value={"other"}>Other</MenuItem>
                                </Select>
                                {touched.gender && errors.gender && <FormHelperText style={{ color: '#961650' }}>{errors.gender}</FormHelperText>}
                            </FormControl>
                            <FormControl fullWidth error={touched.nationality && errors.nationality}>
                                <InputLabel id="demo-simple-select-label">{t("Nationality")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='nationality'
                                    value={values.nationality}
                                    onBlur={handleBlur}
                                    label={t("Nationality")}
                                    onChange={handleChange}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                >
                                    {countriesData?.map((item, index) => (
                                        <MenuItem key={index} value={item._id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touched.nationality && errors.nationality && <FormHelperText style={{ color: '#961650' }}>{errors.nationality}</FormHelperText>}
                            </FormControl>
                        </Stack>
                        <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                            <FormControl fullWidth error={touched.language && errors.language}>
                                <InputLabel id="demo-simple-select-label">{t("Language")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='language'
                                    value={values.language}
                                    label={t("Language")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                >
                                    {languagesData?.map((item, index) => (
                                        <MenuItem key={index} value={item._id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touched.language && errors.language && <FormHelperText style={{ color: '#961650' }}>{errors.language}</FormHelperText>}
                            </FormControl>
                            <FormControl fullWidth disabled={!values.language} error={touched.languageLevel && errors.languageLevel}>
                                <InputLabel id="demo-simple-select-label">{t("Language Level")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="languageLevel"
                                    value={values.languageLevel}
                                    onBlur={handleBlur}
                                    label={t("Language Level")}
                                    onChange={handleChange}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                >
                                    {languageLevelsData?.map((item, index) => (
                                        <MenuItem key={index} value={item._id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touched.languageLevel && errors.languageLevel && <FormHelperText style={{ color: '#961650' }}>{errors.languageLevel}</FormHelperText>}
                            </FormControl>
                        </Stack>
                        <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                            <FormControl fullWidth error={touched.country && errors.country}>
                                <InputLabel id="demo-simple-select-label">{t("Country")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='country'
                                    value={values.country}
                                    label={t("Country")}
                                    onChange={handleChange}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                >
                                    {countriesData?.map((item, index) => (
                                        <MenuItem key={index} value={item._id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touched.country && errors.country && <FormHelperText style={{ color: '#961650' }}>{errors.country}</FormHelperText>}
                            </FormControl>
                            <FormControl fullWidth disabled={!values.country} error={touched.city && errors.city}>
                                <InputLabel id="demo-simple-select-label">{t("City")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.city}
                                    name='city'
                                    label={t("City")}
                                    onChange={handleChange}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                >
                                    {citiesData?.map((item, index) => (
                                        <MenuItem key={index} value={item._id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touched.city && errors.city && <FormHelperText style={{ color: '#961650' }}>{errors.city}</FormHelperText>}
                            </FormControl>

                        </Stack>
                        <Stack spacing={2} p={2} flexDirection={{ xs: "column", lg: "row" }}>
                            <TextField
                                inputProps={{ maxLength: 150 }}
                                name="address"
                                error={touched.address && errors.address}
                                helperText={touched.address && errors.address}
                                onBlur={handleBlur}
                                label={t("Address")}
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                                value={values.address} />
                        </Stack>



                    </Card>

                    {!smUp && <Stack flexDirection={{ xs: "column", lg: "row" }} justifyContent="space-between" mt={2}>
                        <Button size='large' variant="contained" color="primary" sx={{ width: { xs: '160px', lg: '160px' }, alignSelf: 'end' }} onClick={handleSubmit}
                            disabled={loading}
                        >
                            <SaveIcon sx={{ mr: 1, float: "left" }} />{loading ? <CircularProgress size={"1rem"} sx={{ ml: 3 }} /> : 'Save Changes'}</Button>
                    </Stack>}

                    <Box height={50} />

                </Grid>
            </Grid>
        </Box>
    </BizLayout >

}

export default Profile

