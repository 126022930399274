import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box, Checkbox, Typography, FormControlLabel, Button } from '@mui/material';
import AuthLayout from '../../layouts/auth/AuthLayout'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack'
import { AuthHead, AuthSeperator, BasicInput, BasicInputPassword, BlackButton } from 'components'
import { useNavigate } from 'react-router';
import { signinFunc, signinWithGoogleFunc } from 'context/auth/auth';
import { FileCopy } from '@mui/icons-material';



const ValidationSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address!')
        .required('Email is required!'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters!')
        .required('Password is required!'),
})


const Signin = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { signinLoading } = useSelector(state => state.auth);
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)

    const { handleSubmit, handleChange, handleBlur, errors, values, touched } = useFormik({
        initialValues: {
            email: '', password: '', remember: false,
        },
        validationSchema: ValidationSchema,
        onSubmit: () => dispatch(signinFunc(values)).then((res) => {

            const twoFa = Boolean(res.payload?.code && res.payload?.token);


            if (res.payload?.accessToken) {

                if (res.payload?.user?.role === 'companyAdmin' ||
                    res.payload?.user?.role === 'intern'
                ) {
                    enqueueSnackbar("You have to log in from the InternIF App section!", { variant: 'warning', autoHideDuration: 2500 })
                    setTimeout(() => {
                        window.location.href = 'https://app.internif.com'
                    }, 2500)
                    localStorage.clear()
                }
                if (res.payload?.user?.role === 'b2bSalesRep' ||
                    res.payload?.user?.role === 'b2bCompanyRep' ||
                    res.payload?.user?.role === 'b2bInternRep' ||
                    res.payload?.user?.role === 'b2bClient'
                ) {
                    enqueueSnackbar("You have to log in from the InternIF Business section!", { variant: 'warning', autoHideDuration: 2500 })
                    setTimeout(() => {
                        window.location.href = 'https://biz.internif.com'
                    }, 2500)
                    localStorage.clear()
                }
                if (res.payload?.user?.role === 'universityAdmin' ||
                    res.payload?.user?.role === 'universityManager'
                ) {
                    enqueueSnackbar("You have to log in from the InternIF University section!", { variant: 'warning', autoHideDuration: 2500 })
                    setTimeout(() => {
                        window.location.href = 'https://uni.internif.com'
                    }, 2500)
                    localStorage.clear()
                }
                if (res.payload?.user?.role === 'internIFFinance' ||
                    res.payload?.user?.role === 'internIFMarketing' ||
                    res.payload?.user?.role === 'internIFTech' ||
                    res.payload?.user?.role === 'internIFManager' ||
                    res.payload?.user?.role === 'internIFInternManager' ||
                    res.payload?.user?.role === 'internIFOperation' ||
                    res.payload?.user?.role === 'internIFCustomerRep' ||
                    res.payload?.user?.role === 'internIFInvestor'
                ) {
                    enqueueSnackbar('Signin successfull!', { variant: 'success' })
                    window.location.href = '/'
                }
            } else if (twoFa) {
                enqueueSnackbar('2FA is required. Code is sent to your email!', { variant: 'warning' })
                console.log(res?.payload?.data)
                navigate('/2fa-signin?token=' + res?.payload?.token)
                process.env.REACT_APP_TEST === "true" && enqueueSnackbar(<div>
                    {res?.payload.code}
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => {
                            const textToCopy = `${res?.payload.code}`
                            const textArea = document.createElement('textarea');
                            textArea.value = textToCopy;
                            document.body.appendChild(textArea);
                            textArea.select();
                            document.execCommand('copy');
                            document.body.removeChild(textArea);
                        }}
                    >
                        Copy Code
                        <FileCopy />
                    </Button>
                </div>, { variant: 'warning', autoHideDuration: 6000 });
            }

            else if (res.payload?.response?.status === 401) {
                enqueueSnackbar('Please check your email or password!', { variant: 'error' })
            }
        }).catch((err) => {
            enqueueSnackbar('Signin failed!', { variant: 'error' })
        }
        )
    })


    useEffect(() => {

        if (profile?.access_token) {
            console.log('provider', provider)
            console.log('profile', profile.access_token)
            dispatch(signinWithGoogleFunc(profile)).then((res) => {
                console.log(res, 'res');
            }).catch((err) => {
                console.log(err, 'err');
            })
        }
    }, [provider, profile, dispatch])

    const CLIENTID = "308592592499-a2a5d1ctvs3rjuoeber0q0ghssmfr0m1.apps.googleusercontent.com"

    return <AuthLayout>
        <Grid item xs={11} sm={9} md={6} lg={5} xl={4} justifyContent="center" alignItems="center" flexDirection={"column"}>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <AuthHead title="InternIF for Management" description="Let's start to transform the future of work!" />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" marginTop="30px">

            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" width="100%">
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <Box flexDirection="column" alignItems="center" gap="10px" width="100%">
                        <BasicInput
                            name="email"
                            type="email"
                            label="Email"
                            placeholder="Enter your email address"
                            value={values?.email}
                            error={touched.email && errors.email}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                        <BasicInputPassword
                            name="password"
                            type="password"
                            label="Password"
                            placeholder="Enter your password"
                            value={values?.password}
                            error={touched.password && errors.password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                        <Box display="flex" justifyContent="space-between" alignItems={'center'} width="100%">
                            <FormControlLabel
                                control={<Checkbox
                                    color="primary"
                                    checked={values?.remember}
                                    onChange={handleChange}
                                    name="remember"
                                />}
                                label={<Typography variant="h7" color="primary.main">
                                    Remember me
                                </Typography>}
                            />
                            <Typography variant="h7" color="info.main" onClick={() => navigate('/forgot-password')} style={{ cursor: 'pointer' }}>
                                Forgot Password?
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%">
                            <BlackButton
                                name="Sign In"
                                type="submit"
                                isActive={values?.email && values?.password && !errors?.email && !errors?.password}
                                handleSubmit={handleSubmit}
                                loading={signinLoading}
                            />
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%" mt={"15px"} mb={"30px"}>
                            {/* <Typography variant="h7" color="primary.300">
                                Don't have an account? <a href="/signup" style={{ textDecoration: 'none' }}><Typography variant="h7" color="info.main" ml={"5px"}>
                                    Sign Up
                                </Typography>
                                </a>
                            </Typography> */}
                        </Box>
                    </Box>
                </form>
            </Box>
        </Grid>
    </AuthLayout >
}

export default Signin