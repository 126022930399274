import { useTranslation } from 'react-i18next';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, Button } from '@mui/material'
import EditNoteIcon from '@mui/icons-material/EditNote';
import Label from 'src/components/label/label';


const InternRepListTable = ({ data, page, pageSize, dataCount, handleChangePage, handleClickOpenList, handleClickOpenEdit }) => {

    const { t } = useTranslation();

    console.log(data, "data")

    return <TableContainer component={Paper} >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>{t("Name")}</TableCell>
                    <TableCell align="center">{t("Interns (B/S/G/V)")}</TableCell>
                    <TableCell align="center">{t("Total Sales ($)")}</TableCell>
                    <TableCell align="center">{t("Medal")}</TableCell>
                    <TableCell align="center">{t("Intern List")}</TableCell>
                    <TableCell align="center">{t("Note")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.length > 0 ?
                    data.map((item, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{page === 1 ? index + 1 : (page - 1) * pageSize + index + 1}</TableCell>
                            <TableCell component="th" scope="row">{item.displayName}</TableCell>
                            <TableCell align="center">{item.internMedalOverview?.bronzeCount ?? 0}/{item.internMedalOverview?.silverCount ?? 0}/{item.internMedalOverview?.goldCount ?? 0}/{item.internMedalOverview?.vipCount ?? 0}</TableCell>
                            <TableCell align="center">$ {item.totalSalesAmount}</TableCell>
                            <TableCell align="center">
                                {<Label variant="filled" color={item.medal}>
                                    {item.medal === 'bronze' ? 'Bronze' :
                                        item.medal === 'silver' ? 'Silver' :
                                            item.medal === 'gold' ? 'Gold' :
                                                item.medal === 'vip' ? 'VIP' : 'None'}
                                </Label>}
                            </TableCell>
                            <TableCell align="center">
                                <Button variant="text" onClick={() => handleClickOpenList(item)} color='primary' size='small' sx={{ textTransform: 'none' }} >
                                    {t("View")}
                                </Button>
                            </TableCell>

                            <TableCell align="center">
                                <EditNoteIcon
                                    onClick={() => handleClickOpenEdit(item)}
                                    sx={{
                                        cursor: 'pointer',
                                        color: '#555',
                                        fontSize: 22,
                                        '&:hover': {
                                            color: '#000',
                                        },
                                    }}
                                />
                            </TableCell>
                            {/*   <TableCell align="center">{item.clientPackageCounts?.standard?.usable} / {item.clientPackageCounts?.pro?.usable} / {item.clientPackageCounts?.special?.usable}</TableCell>
                            <TableCell align="center">$ {item.totalSalesTo}</TableCell>*/}


                        </TableRow>
                    )) :
                    <TableRow>
                        <TableCell
                            colSpan={9}
                            align="center">{t('No data found')}</TableCell>
                    </TableRow>
                }
            </TableBody>

        </Table>

        <Box display="flex" justifyContent="center" p={2}>
            <Pagination
                count={Math.ceil(dataCount / pageSize)}
                page={page}
                onChange={handleChangePage}
                color="primary"
            />
        </Box>
    </TableContainer>
}

export default InternRepListTable
