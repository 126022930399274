
import BizLayout from "layouts/biz/BizLayout"
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Ticket = () => {

    const { t } = useTranslation()




    return <BizLayout>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t("Ticket")}</Typography>
            <Typography variant="body2" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 3 }}>{t("There is no ticket yet.")}</Typography>
        </Box>

    </BizLayout>
}

export default Ticket