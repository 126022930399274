import { useSelector } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import BizLayout from 'layouts/biz/BizLayout'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';
import { MedalBox } from "components";

const info = [
    "finance ve ticket verileri için backend bekleniyor.",
]

const Dashboard = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { internIFManagerOverviewData } = useSelector(state => state.commonForManager);

    console.log(internIFManagerOverviewData)

    return <BizLayout info={info}>
        <Box pl={{ xs: 0, lg: 4 }} >
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 0 }}>{t('Dashboard')}</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6} mt={2}>
                    <Box
                        onClick={() => navigate('/agent')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }}
                        display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Agent')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Bronze')}
                                    bgColor="#FFEFE2"
                                    count={internIFManagerOverviewData?.salesRepsOverview?.bronzeCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Silver')}
                                    bgColor="#F5F5F5"
                                    count={internIFManagerOverviewData?.salesRepsOverview?.silverCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Gold')}
                                    bgColor="#fff1ba"
                                    count={internIFManagerOverviewData?.salesRepsOverview?.goldCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('VIP')}
                                    bgColor="#383e42"
                                    count={internIFManagerOverviewData?.salesRepsOverview?.vipCount || 0}
                                    uppercase
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6} mt={2}>
                    <Box
                        onClick={() => navigate('/business-partner')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }}
                        display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Business Partner')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Bronze')}
                                    bgColor="#FFEFE2"
                                    count={internIFManagerOverviewData?.clientsOverview?.bronzeCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Silver')}
                                    bgColor="#F5F5F5"
                                    count={internIFManagerOverviewData?.clientsOverview?.silverCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Gold')}
                                    bgColor="#fff1ba"
                                    count={internIFManagerOverviewData?.clientsOverview?.goldCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('VIP')}
                                    bgColor="#383e42"
                                    count={internIFManagerOverviewData?.clientsOverview?.vipCount || 0}
                                    uppercase
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6} mt={2}>
                    <Box
                        onClick={() => navigate('/university')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }}
                        display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Universities')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Bronze')}
                                    bgColor="#FFEFE2"
                                    count={internIFManagerOverviewData?.universitiesOverview?.bronzeCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Silver')}
                                    bgColor="#F5F5F5"
                                    count={internIFManagerOverviewData?.universitiesOverview?.silverCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Gold')}
                                    bgColor="#fff1ba"
                                    count={internIFManagerOverviewData?.universitiesOverview?.goldCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('VIP')}
                                    bgColor="#383e42"
                                    count={internIFManagerOverviewData?.universitiesOverview?.vipCount || 0}
                                    uppercase
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6} mt={2}>
                    <Box
                        onClick={() => navigate('/company-agent')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }}
                        display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Company Agent')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Bronze')}
                                    bgColor="#FFEFE2"
                                    count={internIFManagerOverviewData?.companyRepsOverview?.bronzeCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Silver')}
                                    bgColor="#F5F5F5"
                                    count={internIFManagerOverviewData?.companyRepsOverview?.silverCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Gold')}
                                    bgColor="#fff1ba"
                                    count={internIFManagerOverviewData?.companyRepsOverview?.goldCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('VIP')}
                                    bgColor="#383e42"
                                    count={internIFManagerOverviewData?.companyRepsOverview?.vipCount || 0}
                                    uppercase
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6} mt={2}>
                    <Box
                        onClick={() => navigate('/company')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }}
                        display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Companies')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Bronze')}
                                    bgColor="#FFEFE2"
                                    count={internIFManagerOverviewData?.companiesOverview?.bronzeCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Silver')}
                                    bgColor="#F5F5F5"
                                    count={internIFManagerOverviewData?.companiesOverview?.silverCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Gold')}
                                    bgColor="#fff1ba"
                                    count={internIFManagerOverviewData?.companiesOverview?.goldCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('VIP')}
                                    bgColor="#383e42"
                                    count={internIFManagerOverviewData?.companiesOverview?.vipCount || 0}
                                    uppercase
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6} mt={2}>
                    <Box
                        onClick={() => navigate('/intern-agent')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }}
                        display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Intern Partner')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Bronze')}
                                    bgColor="#FFEFE2"
                                    count={internIFManagerOverviewData?.internRepsOverview?.bronzeCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Silver')}
                                    bgColor="#F5F5F5"
                                    count={internIFManagerOverviewData?.internRepsOverview?.silverCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('Gold')}
                                    bgColor="#fff1ba"
                                    count={internIFManagerOverviewData?.internRepsOverview?.goldCount || 0}
                                    uppercase
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MedalBox
                                    medalType={t('VIP')}
                                    bgColor="#383e42"
                                    count={internIFManagerOverviewData?.internRepsOverview?.vipCount || 0}
                                    uppercase
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12} mt={2}>
                    <Box onClick={() => navigate('/finance')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Finance')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Package Sales')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Revenue')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Withdrawn')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Withdrawable')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12} mt={2}>
                    <Box onClick={() => navigate('/ticket')}
                        sx={{
                            boxShadow: 5, p: 2, borderRadius: 2, bgcolor: 'white', cursor: 'pointer',
                            '&:hover': { boxShadow: 15 }
                        }} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                        <Typography variant='h3'>{t('Tickets')}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Open')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Waiting')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Answered')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Box bgcolor={"#F5F5F5"} py={2} gap={1} display="flex" flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"} borderRadius={2}>
                                    <Typography variant="h6">{t('Closed')}</Typography>
                                    <Typography variant="h2">0</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    </BizLayout>
}

export default Dashboard