import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    signinData: null,
    signinLoading: false,
    signinError: null,
    accessToken: null,
    email: null,
    userRole: null,
    signupData: null,
    signupLoading: false,
    signupError: null,
    resendConfirmationCodeData: null,
    resendConfirmationCodeLoading: false,
    resendConfirmationCodeError: null,
    confirmationEmailData: null,
    confirmationEmailLoading: false,
    confirmationEmailError: null,
    resetPasswordData: null,
    resetPasswordLoading: false,
    resetPasswordError: null,
    resetPasswordUsingOldData: null,
    resetPasswordUsingOldLoading: false,
    resetPasswordUsingOldError: null,
    requestResetPasswordData: null,
    requestResetPasswordLoading: false,
    requestResetPasswordError: null,
    pendingUsersData: null,
    pendingUsersCount: null,
    pendingUsersLoading: false,
    pendingUsersError: null,
    internIFManager_registerData: null,
    internIFManager_registerLoading: false,
    internIFManager_registerError: null,
    get2faData: null,
    get2faLoading: false,
    get2faError: null,
    update2faData: null,
    update2faLoading: false,
    update2faError: null,
    confirm2faData: null,
    confirm2faLoading: false,
    confirm2faError: null,
    confirmRegistirationByUserData: null,
    confirmRegistirationByUserLoading: false,
    confirmRegistirationByUserError: null,
};


export const signinFunc = createAsyncThunk("auth/signinFunc", async (values) => {
    var data1 = JSON.stringify({
        "email": values.email,
        "password": values.password
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/login', data1);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const signinWithGoogleFunc = createAsyncThunk("auth/signinWithGoogleFunc", async (values) => {

    var data1 = JSON.stringify({
        "token": values.access_token
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/oauth/google/login', data1);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const signupFunc = createAsyncThunk("auth/signupFunc", async (values) => {

    let data1 = JSON.stringify({
        "email": values.email,
        "password": values.password,
        "role": values.role
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + '/auth/auth/register',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer null'
        },
        data: data1
    };

    const response = axios.request(config)
        .then((res) => {

            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});

export const resendConfirmationCodeFunc = createAsyncThunk("auth/resendConfirmationCodeFunc", async (values) => {
    let data1 = JSON.stringify({
        "email": values.email
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/resendRegistrationConfirmation', data1);


    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});

export const confirmationEmailFunc = createAsyncThunk("auth/confirmationEmailFunc", async (values) => {
    let data1 = JSON.stringify({
        "token": values.token,
        "code": values.code
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/confirmRegistration', data1);

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});

export const resetPasswordFunc = createAsyncThunk("auth/resetPasswordFunc", async (values) => {
    let data1 = JSON.stringify({
        "token": values.passwordToken,
        "password": values.password
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/resetpassword', data1);

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});

export const requestResetPasswordFunc = createAsyncThunk("auth/requestResetPasswordFunc", async (values) => {
    let data1 = JSON.stringify({
        "email": values.email
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/requestresetpassword', data1);

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});

export const resetPasswordUsingOldFunc = createAsyncThunk("auth/resetPasswordUsingOldFunc", async (values) => {

    console.log(values, '!3123123');

    var data1 = JSON.stringify({
        "oldPassword": values.oldPassword,
        "password": values.newPassword
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + '/auth/auth/resetpassword',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + values?.accessToken
        },
        data: data1
    };

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const pendingUsersFunc = createAsyncThunk("auth/pendingUsersFunc", async (values) => {

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + '/auth/users?status=' + values.status + "&role=" + values.role + "&page=" + values.page + "&pageSize=" + values.pageSize,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + values?.accessToken
        }
    };

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


export const internIFManager_registerFunc = createAsyncThunk("auth/internIFManager_registerFunc", async (values) => {

    console.log(values)
    var data1 = JSON.stringify({
        "email": values.email,
        "name": values.name,
        "surname": values.surname,
        "role": values.role,
        "password": values.password,
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/internIFManager_register', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});

export const get2faFunc = createAsyncThunk("auth/get2faFunc", async (values) => {
    let data1 = '';

    var config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + '/auth/user/twoFactorAuthOptions',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + values.accessToken
        },
        data: data1
    };

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});

export const update2faFunc = createAsyncThunk("auth/update2faFunc", async (values) => {
    let data1 = JSON.stringify({
        "email": values.email
    });

    var config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + '/auth/user/twoFactorAuthOptions',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + values.accessToken
        },
        data: data1
    };

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});

export const confirm2faFunc = createAsyncThunk("auth/confirm2faFunc", async (values) => {
    let data1 = JSON.stringify({
        "token": values.token,
        "code": values.code
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/confirmTwoFactorAuth', data1);

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});

export const confirmRegistirationByUserFunc = createAsyncThunk("auth/confirmRegistirationByUserFunc", async (values) => {
    let data1 = JSON.stringify({
        "token": values.token,
        "code": values.code,
        "password": values.password,
        "resetPasswordToken": values.resetPasswordToken
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/confirmRegistrationByUser', data1);

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});



const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserRole: (state, action) => {
            state.userRole = action.payload;
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        removeUser: (state) => {
            state.signinData = null;
            state.signinLoading = false;
            state.signinError = null
            state.accessToken = null;
            state.email = null;
            state.userRole = null;
            localStorage.clear();
        }
    },
    extraReducers: (builder) => {

        // signin
        builder.addCase(signinFunc.pending, (state) => {
            state.signinLoading = true;
        });
        builder.addCase(signinFunc.fulfilled, (state, action) => {
            state.signinLoading = false;
            state.signinData = action.payload;
            state.accessToken = action.payload.accessToken;
            state.email = action.payload.user?.email;
            state.userRole = action.payload.user?.role;
            state.signinError = action.payload.message;
            localStorage.setItem('accessToken', action.payload.accessToken);
            localStorage.setItem('email', action.payload.user?.email);
            localStorage.setItem('userRole', action.payload.user?.role);
            localStorage.setItem('userId', action.payload.user?.userId);
        });
        builder.addCase(signinFunc.rejected, (state, action) => {
            state.signinLoading = false;
            state.signinError = action.error.message;
        });

        // signup
        builder.addCase(signupFunc.pending, (state) => {
            state.signupLoading = true;
        });
        builder.addCase(signupFunc.fulfilled, (state, action) => {
            state.signupLoading = false;
            state.signupData = action.payload;
            state.signupError = action.payload.message;
        });
        builder.addCase(signupFunc.rejected, (state, action) => {
            state.signupLoading = false;
            state.signupError = action.error.message;
        });

        // resendConfirmationCode
        builder.addCase(resendConfirmationCodeFunc.pending, (state) => {
            state.resendConfirmationCodeLoading = true;
        }
        );
        builder.addCase(resendConfirmationCodeFunc.fulfilled, (state, action) => {
            state.resendConfirmationCodeLoading = false;
            state.resendConfirmationCodeData = action.payload;
            state.resendConfirmationCodeError = action.payload.message;
        }
        );
        builder.addCase(resendConfirmationCodeFunc.rejected, (state, action) => {
            state.resendConfirmationCodeLoading = false;
            state.resendConfirmationCodeError = action.error.message;
        }
        );

        // confirmationEmail
        builder.addCase(confirmationEmailFunc.pending, (state) => {
            state.confirmationEmailLoading = true;
        }
        );
        builder.addCase(confirmationEmailFunc.fulfilled, (state, action) => {
            state.confirmationEmailLoading = false;
            state.confirmationEmailData = action.payload;
            state.confirmationEmailError = action.payload.message;
        }
        );
        builder.addCase(confirmationEmailFunc.rejected, (state, action) => {
            state.confirmationEmailLoading = false;
            state.confirmationEmailError = action.error.message;
        }
        );

        // resetPassword
        builder.addCase(resetPasswordFunc.pending, (state) => {
            state.resetPasswordLoading = true;
        }
        );
        builder.addCase(resetPasswordFunc.fulfilled, (state, action) => {
            state.resetPasswordLoading = false;
            state.resetPasswordData = action.payload;
            state.resetPasswordError = action.payload.message;
        }
        );
        builder.addCase(resetPasswordFunc.rejected, (state, action) => {
            state.resetPasswordLoading = false;
            state.resetPasswordError = action.error.message;
        }
        );

        // resetPasswordUsingOld
        builder.addCase(resetPasswordUsingOldFunc.pending, (state) => {
            state.resetPasswordUsingOldLoading = true;
        });
        builder.addCase(resetPasswordUsingOldFunc.fulfilled, (state, action) => {
            state.resetPasswordUsingOldLoading = false;
            state.resetPasswordUsingOldData = action.payload;
            state.resetPasswordUsingOldError = action.payload.message;
        });
        builder.addCase(resetPasswordUsingOldFunc.rejected, (state, action) => {
            state.resetPasswordUsingOldLoading = false;
            state.resetPasswordUsingOldError = action.error.message;
        });

        // requestResetPassword
        builder.addCase(requestResetPasswordFunc.pending, (state) => {
            state.requestResetPasswordLoading = true;
        });
        builder.addCase(requestResetPasswordFunc.fulfilled, (state, action) => {
            state.requestResetPasswordLoading = false;
            state.requestResetPasswordData = action.payload;
            state.requestResetPasswordError = action.payload.message;
        });
        builder.addCase(requestResetPasswordFunc.rejected, (state, action) => {
            state.requestResetPasswordLoading = false;
            state.requestResetPasswordError = action.error.message;
        });

        // pendingUsers
        builder.addCase(pendingUsersFunc.pending, (state) => {
            state.pendingUsersLoading = true;
        });
        builder.addCase(pendingUsersFunc.fulfilled, (state, action) => {
            state.pendingUsersLoading = false;
            state.pendingUsersData = action.payload.data?.data;
            state.pendingUsersCount = action.payload.data?.count;
            state.pendingUsersError = action.payload.message;
        });
        builder.addCase(pendingUsersFunc.rejected, (state, action) => {
            state.pendingUsersLoading = false;
            state.pendingUsersError = action.error.message;
        });

        // InternIF Manager Register

        builder.addCase(internIFManager_registerFunc.pending, (state) => {
            state.internIFManager_registerLoading = true;
        });
        builder.addCase(internIFManager_registerFunc.fulfilled, (state, action) => {
            state.internIFManager_registerLoading = false;
            state.internIFManager_registerData = action.payload;
            state.internIFManager_registerError = action.payload.message;
        });
        builder.addCase(internIFManager_registerFunc.rejected, (state, action) => {
            state.internIFManager_registerLoading = false;
            state.internIFManager_registerError = action.error.message;
        });

        //get2fa
        builder.addCase(get2faFunc.pending, (state) => {
            state.get2faLoading = true;
        }
        );
        builder.addCase(get2faFunc.fulfilled, (state, action) => {
            state.get2faLoading = false;
            state.get2faData = action.payload?.data;
            state.get2faError = action.payload.message;
        }
        );
        builder.addCase(get2faFunc.rejected, (state, action) => {
            state.get2faLoading = false;
            state.get2faError = action.error.message;
        }
        );

        //update2fa
        builder.addCase(update2faFunc.pending, (state) => {
            state.update2faLoading = true;
        }
        );
        builder.addCase(update2faFunc.fulfilled, (state, action) => {
            state.update2faLoading = false;
            state.update2faData = action.payload;
            state.update2faError = action.payload.message;
        }
        );
        builder.addCase(update2faFunc.rejected, (state, action) => {
            state.update2faLoading = false;
            state.update2faError = action.error.message;
        }
        );

        //confirm2fa
        builder.addCase(confirm2faFunc.pending, (state) => {
            state.confirm2faLoading = true;
        }
        );
        builder.addCase(confirm2faFunc.fulfilled, (state, action) => {
            state.confirm2faLoading = false;
            state.confirm2faData = action.payload;
            state.confirm2faError = action.payload.message;
        }
        );
        builder.addCase(confirm2faFunc.rejected, (state, action) => {
            state.confirm2faLoading = false;
            state.confirm2faError = action.error.message;
        }
        );

        //confirmRegistirationByUser
        builder.addCase(confirmRegistirationByUserFunc.pending, (state) => {
            state.confirmRegistirationByUserLoading = true;
        }
        );
        builder.addCase(confirmRegistirationByUserFunc.fulfilled, (state, action) => {
            state.confirmRegistirationByUserLoading = false;
            state.confirmRegistirationByUserData = action.payload;
            state.confirmRegistirationByUserError = action.payload.message;
        }
        );
        builder.addCase(confirmRegistirationByUserFunc.rejected, (state, action) => {
            state.confirmRegistirationByUserLoading = false;
            state.confirmRegistirationByUserError = action.error.message;
        }
        );


    }
});

export const { setUserRole, removeUser, setAccessToken, setEmail } = auth.actions;

export default auth.reducer;