import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
  internListData: null,
  internListCount: null,
  internListLoading: false,
  internListError: null,
  internsOverviewData: null,
  internsOverviewLoading: false,
  internsOverviewError: null,
};


export const internListFunc = createAsyncThunk("company/internListFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query InternRep_interns($pagination: Pagination) {
      internRep_interns(pagination: $pagination) {
        count
          data {
            _id
            displayName
            totalSalesTo
            medal
            displayImageUrl
            organizationType
            country {
              _id
              name
            }
            city {
              _id
              name
            }
            companyAdmin {
              _id
              displayName
            }
            numberOfEmployees {
              _id
              name
            }
          }
      }
    }`,
    variables: {
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


export const internsOverviewFunc = createAsyncThunk("company/internsOverviewFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query InternRep_dashboardOverviews {
      internRep_dashboardOverviews {
        internsOverview {
          bronzeCount
          silverCount
          goldCount
          vipCount
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});




const company = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(internListFunc.pending, (state) => {
      state.internListLoading = true;
    }
    );
    builder.addCase(internListFunc.fulfilled, (state, action) => {
      state.internListLoading = false;
      state.internListData = action.payload?.companyRep_companies?.data;
      state.internListCount = action.payload?.companyRep_companies?.count;
      state.internListError = action.payload.message;
    }
    );
    builder.addCase(internListFunc.rejected, (state, action) => {
      state.internListLoading = false;
      state.internListError = action.error.message;
    }
    );
    builder.addCase(internsOverviewFunc.pending, (state) => {
      state.internsOverviewLoading = true;
    }
    );
    builder.addCase(internsOverviewFunc.fulfilled, (state, action) => {
      state.internsOverviewLoading = false;
      state.internsOverviewData = action.payload?.internRep_dashboardOverviews?.internsOverview;
      state.internsOverviewError = action.payload.message;
    }
    );
    builder.addCase(internsOverviewFunc.rejected, (state, action) => {
      state.internsOverviewLoading = false;
      state.internsOverviewError = action.error.message;
    }
    );
  }
});


export default company.reducer;