import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
  salesRepListData: null,
  salesRepListCount: null,
  salesRepListLoading: false,
  salesRepListError: null,
  clientsOfSalesRepData: null,
  clientsOfSalesRepCount: null,
  clientsOfSalesRepLoading: false,
  clientsOfSalesRepError: null,
};


export const salesRepListFunc = createAsyncThunk("salesRep/salesRepListFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Data($pagination: Pagination) {
      internIFManager_salesReps(pagination: $pagination) {
        count
        data {
          _id
          role
          displayName
          displayImageUrl
          salesRepType
          contact {
            email
          }
          medal
          totalSalesAmount
          universityMedalOverview {
            vipCount
            silverCount
            goldCount
            bronzeCount
          }
          b2bClientMedalOverview {
            bronzeCount
            goldCount
            silverCount
            vipCount
          }
        }
      }
    }`,
    variables: {
      "pagination": {
        "page": values.page,
        "pageSize": values.pageSize
      }
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

export const clientsOfSalesRepFunc = createAsyncThunk("salesRep/clientsOfSalesRepFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query InternIFManager_clientsOfSalesRep($salesRepId: ID!) {
      internIFManager_clientsOfSalesRep(salesRepId: $salesRepId) {
        data {
          clientCompanyName
          clientPackageCounts {
            standard {
              total
              usable
            }
            special {
              total
              usable
            }
            pro {
              total
              usable
            }
          }
          contact {
            email
          }
          displayImageUrl
          displayName
          medal
          totalSalesTo
        }
      }
    }`,
    variables: {
      "salesRepId": values?.salesRepId
    }
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/management/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});

const salesRep = createSlice({
  name: "salesRep",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(salesRepListFunc.pending, (state) => {
      state.salesRepListLoading = true;
    }
    );
    builder.addCase(salesRepListFunc.fulfilled, (state, action) => {
      state.salesRepListLoading = false;
      console.log(action.payload, "action.payload");
      state.salesRepListData = action.payload?.internIFManager_salesReps?.data;
      state.salesRepListCount = action.payload?.internIFManager_salesReps?.count;
      state.salesRepListError = action.payload.message;
    }
    );
    builder.addCase(salesRepListFunc.rejected, (state, action) => {
      state.salesRepListLoading = false;
      state.salesRepListError = action.error.message;
    }
    );

    builder.addCase(clientsOfSalesRepFunc.pending, (state) => {
      state.clientsOfSalesRepLoading = true;
    }
    );
    builder.addCase(clientsOfSalesRepFunc.fulfilled, (state, action) => {
      state.clientsOfSalesRepLoading = false;
      state.clientsOfSalesRepData = action.payload?.internIFManager_clientsOfSalesRep?.data;
      state.clientsOfSalesRepCount = action.payload?.internIFManager_clientsOfSalesRep?.count;
      state.clientsOfSalesRepError = action.payload.message;
    }
    );
    builder.addCase(clientsOfSalesRepFunc.rejected, (state, action) => {
      state.clientsOfSalesRepLoading = false;
      state.clientsOfSalesRepError = action.error.message;
    }
    );

  }
});



export default salesRep.reducer;